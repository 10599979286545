import React, {Component} from "react";
import "animate.css/animate.min.css";
import 'pages/homePage/css/HomePage.scss';
import {HomeTraining} from "components/home/HomeTraining";
import {HomeQuestions} from "components/home/HomeQuestions";
import {HomeFunding} from "components/home/HomeFunding";
import Success from 'assets/images/success.svg';
import {Helmet} from "react-helmet";
import Footer from "components/footer/Footer";
import HomeFilters from "components/home/HomeFilters";

class HomePage extends Component {

    state = {
        firstVisit: false,
    };

    componentDidMount() {
        const firstVisit = sessionStorage.getItem('homePageVisited');
        sessionStorage.setItem('homePageVisited', 'true');
        this.setState({
            firstVisit: firstVisit === null
        })
    }

    render = () => {
        window.scrollTo(0, 0);

        return (
            <React.Fragment>
                <Helmet>
                    <title>Meritize Connect - Build Your Path to a Meaningful Career</title>
                    <meta name="description"
                          content="It takes a lot to get to where you want to be. Start here: explore training options, contact programs you’re interested in, and apply for funding all in one place."/>
                    <link rel="canonical" href="https://www.meritizeconnect.com"/>
                </Helmet>
                <div className='home-page--mobile'>
                    <img src={Success} className='home-page-mobile__image' alt=''/>
                    <HomeFilters history={this.props.history} firstVisit={this.state.firstVisit}/>
                </div>
                <div className='home-page--desktop'>
                    <HomeFilters history={this.props.history} firstVisit={this.state.firstVisit}/>
                </div>
                <HomeTraining/>
                <HomeQuestions/>
                <HomeFunding/>
                <Footer/>
            </React.Fragment>
        );
    };
};

export default HomePage;