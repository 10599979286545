import React, {Component} from 'react';
import 'components/sponsored-footer/css/Sponsored-footer.scss';
import {InfoOutlined} from "@material-ui/icons";
import {Popper} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

class SponsoredFooter extends Component {

    state = {
        anchorElement: null
    };

    _openPopover = (event) => {
        this._stopEvent(event);
        this.setState({anchorElement: event.currentTarget});
    };

    _closePopover = (event) => {
        this._stopEvent(event);
        this.setState({anchorElement: null})
    };

    _stopEvent = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    render = () => (
        <div className="sponsored">
            <div className="sponsored-pill__container_footer"
                 onClick={this._openPopover}
            >
                <div className="sponsored-pill__text_footer">Sponsored by Meritize</div>
                <InfoOutlined className="sponsored-pill__icon_footer"></InfoOutlined>
            </div>
            {this.state.anchorElement && <div className='sponsored-popover__background'></div>}
            <ClickAwayListener onClickAway={this._closePopover}>
                <Popper
                    open={!!this.state.anchorElement}
                    anchorEl={this.state.anchorElement}
                    className='sponsored-popover'
                >
                    <div
                        onClick={this._stopEvent}
                        className='sponsored-popover__container'
                    >
                        <div className="sponsored-popover__header">
                            <div className="sponsored-popover__title">
                                Sponsored by Meritize
                            </div>
                            <div className='sponsored-popover__close-button' onClick={this._closePopover}>
                                <CloseIcon className='sponsored-popover__close-icon'/>
                            </div>
                        </div>
                        <div className="sponsored-popover__description">
                            Meet certain conditions and Meritize Training
                            may cover your loan payments.**
                        </div>
                    </div>
                </Popper>
            </ClickAwayListener>
        </div>
    );
}

export default SponsoredFooter;