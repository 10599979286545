import Environment from "util/Environment";
import * as React from "react";
import './css/DynamicSearch.scss';
import axios from "axios";
import Input from "@material-ui/core/Input";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CallMadeIcon from '@material-ui/icons/CallMade';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import MenuItem from "@material-ui/core/MenuItem";
import Downshift from "downshift";
import {throttle} from "throttle-debounce";

export class DynamicSearch extends React.Component {

    state = {
        categories: [],
        suggestions: [],
        searchedText: '',
    };

    constructor(props) {
        super(props);
        this._getSuggestionsThrottled = throttle(500, this._getSuggestions);
    }

    componentDidMount = () => {
        axios.get(`${Environment.apiUrl()}/categories`).then(response => {
            if (response.data) {
                this.setState({
                    categories: response.data,
                    suggestions: response.data.map(category => category.description),
                    searchedText: this.props.term,
                });
            }
        });
    };

    _getSuggestions = (search) => {
        if (search.length >= 3) {
            axios.get(`${Environment.apiUrl()}/programs/suggestions`, {
                params: {
                    term: search
                },
                headers: {
                    'Session-Id': sessionStorage.getItem('connectSessionId'),
                    'Page-Url': window.location.href
                }
            }).then(response => {
                    if (response.data) {
                        this.setState({
                            suggestions: [...(this.state.categories.map(category => category.description)), ...response.data],
                        });
                    }
                }
            );
        }
    };

    _clearDynamicSearchInput = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.clearSearchTerm();
        this.setState({
            searchedText: '',
            suggestions: this.state.categories.map(category => category.description)
        });
    };

    _getFilteredSuggestions = (search) => {
        let filteredCategories = this.state.categories.map(category => category.description);
        if (search && search.length >= 3) {
            filteredCategories = [...new Set(this.state.suggestions.filter(suggestion => suggestion.toLowerCase().indexOf(search.toLowerCase()) !== -1))];
            if (filteredCategories.length === 0) {
                return this.state.categories.map(category => category.description);
            }
        }
        return filteredCategories;
    };

    _searchForTerm = (suggestion) => {
        if (suggestion) {
            this.setState({
                searchedText: suggestion
            });
            this.props.setSearchTerm(suggestion);
        }
        document.getElementsByClassName('dynamic-search__input-field')[0].blur();
    };

    _stateReducer = (_, changes) => {
        if (changes.type === Downshift.stateChangeTypes.keyDownEscape)
            return {isOpen: false};
        return changes;
    };

    _handleChangeInput = (inputProps) => (event) => {
        this._getSuggestionsThrottled(event.target.value);

        inputProps.onChange(event);
        if(!event.target.value){this._clearDynamicSearchInput(event);}
        else {
            this.setState({
                searchedText: event.target.value
            });
        }
    };

    render = () => (
        <Downshift
            onSelect={(suggestion) => this._searchForTerm(suggestion)}
            stateReducer={this._stateReducer}
        >
            {({
                  getInputProps,
                  getItemProps,
                  highlightedIndex,
                  isOpen,
                  openMenu,
              }) => (<div className='dynamic-search'>
                <Input
                    className={`dynamic-search__input ${isOpen && 'dynamic-search__input--suggestions'}`}
                    placeholder={'Search for programs, industries, schools'}
                    inputProps={{
                        className: 'dynamic-search__input-field',
                    }}
                    {...getInputProps({
                        onFocus: openMenu,
                        onClick: openMenu
                    })}
                    key={isOpen}
                    autoFocus={isOpen}
                    disableUnderline={true}
                    value={this.state.searchedText}
                    onChange={this._handleChangeInput(getInputProps())}
                    startAdornment={
                        <React.Fragment>
                            <SearchIcon className="dynamic-search__icon-search"/>
                            <ArrowBackIosIcon className="dynamic-search__icon-back"/>
                        </React.Fragment>
                    }
                    endAdornment={this.state.searchedText && !isOpen &&
                    <CloseIcon className='dynamic-search__clear-icon' onClick={this._clearDynamicSearchInput}/>}
                />
                {isOpen &&
                <React.Fragment>
                    <div className='dynamic-search__suggestions-background--mobile'></div>
                    <div className='dynamic-search__suggestions'>
                        {this._getFilteredSuggestions(this.state.searchedText).map((suggestion, index) => (

                                <MenuItem
                                    className='dynamic-search-suggestions__item'
                                    key={index}
                                    {...getItemProps({item: suggestion})}
                                    selected={index === highlightedIndex}
                                    component="div"
                                >

                                    <CallMadeIcon className='dynamic-search-suggestions__icon'/>
                                    {suggestion}
                                </MenuItem>
                            )
                        )}
                    </div>
                </React.Fragment>}
            </div>)}
        </Downshift>
    );
}