import React, {Component} from 'react';
import 'components/card/css/Card.scss';
import Icon from "@material-ui/core/Icon";
import Sponsored from "components/sponsored/Sponsored";
import {Link} from "react-router-dom";
import {MeritizeTrainingCampus} from "components/meritizeTrainingCampus/MeritizeTrainingCampus";
import PropTypes from "prop-types";

class Card extends Component {
    getScheduleHours = (isFullTime) => isFullTime ? 'Full-Time' : 'Part-Time';

    isPartnerProgram = (nonPartnerTrainingClass, partnerTrainingClass) => this.props.program.isPartnerTraining ? partnerTrainingClass : nonPartnerTrainingClass;

    getProgramDetailsURL = (program) => {
        let params = (!!program.programName ? program.programName.replace(/ /g, "-") : "")
            + '-' + (!!program.schoolName ? program.schoolName.replace(/ /g, "-") : "")
            + '-' + (!!program.location ? program.location.replace(/,/g, "").replace(/ /g, "-") : "");

        return (`/program/${program.id}/programinfo/${params}`);
    };

    render() {
        let program = this.props.program;
        let previousUrl = `${this.props.history.location.pathname}${this.props.history.location.search}`;
        return (
            <React.Fragment>
                <div className="card__content">
                    <Link to={{ pathname: this.getProgramDetailsURL(program), previousUrl: previousUrl }}
                       className="card__program-details">
                        <div className='schedule'>
                            <Icon className="card__icon">access_time</Icon>
                            <span>{this.getScheduleHours(program.isFullTime)}</span>
                        </div>
                        <div className="duration">
                            <Icon className="card__icon">hourglass_empty</Icon>
                            <span>{program.duration}</span>
                        </div>

                        <h2
                            className={this.isPartnerProgram(
                                'program',
                                'program program__meritize-training'
                            )}
                        >
                            {program.programName}
                        </h2>
                        <div className={
                            this.isPartnerProgram(
                                'school',
                                'school school__meritize-training')
                        }>
                            {this.isPartnerProgram(program.schoolName,
                                <MeritizeTrainingCampus/>)}
                        </div>
                        <div className='industry'>{program.industryCategory}</div>
                        <div className="location">{program.location}</div>

                        {!program.isPartnerTraining &&
                        <React.Fragment>
                            <div className="tuition">{program.tuition}</div>
                            <div className="total-cost-label">TUITION</div>
                        </React.Fragment>
                        }
                    </Link>

                    {program.isPartnerTraining ?
                        <div className="card__sponsored-section"><Sponsored/></div>
                        : <React.Fragment></React.Fragment>
                    }

                </div>
            </React.Fragment>
        );
    }
}

Card.propTypes = {
    previousUrl: PropTypes.string,
    history: PropTypes.any
};

export default Card;