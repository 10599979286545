import React from 'react';
import 'components/header/css/Header.scss';
import logo from 'assets/images/meritizeconnect-logo.svg';
import mobilelogo from 'assets/images/mobile-logo.svg';
import winterLogo from 'assets/images/seasonalBanners/meritize-wordmark-winter.svg';
import {getCurrentDate} from "../../util/DateHelper";

const Header = () => {

    const isWinter = () => {
        const dt = getCurrentDate()
        const month = dt.getMonth() + 1;
        const day = dt.getDate()

        return month == 12
            ? day >= 15
            : month == 3
                ? day < 21
                : month < 3;
    }

    return (
        <>
        ${ isWinter() ?
            <div className="header header-winter">
                <a href="/">
                    <img className="desktop-logo desktop-logo-winter"  alt="Meritize Connect - Find Training Programs" src={ winterLogo } />
                <img className="mobile-logo mobile-logo-winter" alt="Meritize Connect - Find Training Programs" src={ winterLogo } />
            </a>
                <div className='header-text'>Find training that fits your life.</div>
            </div>
           :
            <div className="header header-default">
                <a href="/">
                    <img className="desktop-logo desktop-logo-default"  alt="Meritize Connect - Find Training Programs" src={ logo } />
                    <img className="mobile-logo mobile-logo-default" alt="Meritize Connect - Find Training Programs" src={ mobilelogo } />
                </a>
                <div className='header-text'>Find training that fits your life.</div>
            </div>
        }
        </>


    );
};

export default Header;