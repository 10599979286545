import React from 'react';
import './css/ProgramDetailsBreadcrumb.scss';
import PropTypes from "prop-types";

const ProgramDetailsBreadcrumb = (props) => {

    const handleSearchResultsClick = () => {
        setTimeout(() => props.history.push(props.previousUrl), 100);
    }

    return (
        <div className="breadcrumbs__container">
            <div className="program-details__breadcrumbs-inner-container">
            <span onClick={handleSearchResultsClick} className="breadcrumbs__links_search">
                Search Results
            </span>
            <i className="material-icons breadcrumbs__material_icon">chevron_right</i>
            <span className="breadcrumbs__links_details1">
                Program Details
            </span>
            </div>
        </div>
    )
}

export default ProgramDetailsBreadcrumb;

ProgramDetailsBreadcrumb.propTypes = {
    history: PropTypes.any,
    previousUrl: PropTypes.string,
};
