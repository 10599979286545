import React from 'react';
import "components/contactProgramButton/css/ContactProgramButton.scss"
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";

const ContactProgramButton = (props) => {
    const generateContactLink = (props) =>
        props.promoCode ?
            `/contact/${props.programId}/?promo=${encodeURI(props.promoCode)}` :
            `/contact/${props.programId}/`;

    let analyticsClass = 'analytics-button-contact-program';

    return (
        <div className="contact-button__contact-program-container">
            <Link className={"contact-button__contact-program-link " + analyticsClass} to={{pathname: generateContactLink(props), previousUrl: props.previousUrl}}>
                <Fab
                    classes={{label: analyticsClass}}
                    disableRipple={true}
                    variant="extended"
                    size="large"
                    aria-label="Add"
                    className={"contact-button__contact-program-button " + analyticsClass}
                >
                    CONTACT PROGRAM
                </Fab>
            </Link>
        </div>
    );
};

ContactProgramButton.propTypes = {
    programId: PropTypes.number.isRequired,
    previousUrl: PropTypes.string,
};

export default ContactProgramButton;