import Environment from "util/Environment";
import React, {Component} from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from 'prop-types';
import Downshift from "downshift";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import axios from "axios"
import 'components/locationDropdown/css/LocationDropdown.scss'
import uuidv4 from "uuid/v4";

const inputPlaceHolder = 'Search by address, city, or zip code';

class LocationDropDown extends Component {

    state = {
        suggestions: [],
        selectedLabel: '',
        previousLocation: undefined,
        showMobileSearch: false,
        sessionToken: undefined,
        sessionTokenCreatedTime: undefined,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedLocation !== state.previousLocation) {
            state.previousLocation = props.selectedLocation;
            state.selectedLabel = props.selectedLocation;
        }
        return state;
    }

    clearSelectedSuggestion = (event) => {
        event.stopPropagation();
        this._clearSearchLocation();
    };

    setSelectedSuggestion = (suggestion) => {
        if (suggestion && !suggestion.disabled) {
            this.setState({
                selectedLabel: suggestion.label,
                sessionToken: undefined,
                sessionTokenCreatedTime: undefined,
            });
            this.hideMobileSearch();

            const apiBaseApi = Environment.apiUrl();
            axios.get(`${apiBaseApi}/locations/details`,
                {
                    params: {
                        placeId: suggestion.placeId,
                        session: suggestion.sessionToken,
                    },
                    headers: {
                        'Session-Id': sessionStorage.getItem('connectSessionId'),
                        'Page-Url': window.location.href
                    }
                }).then(response => {
                this.props.onSelection(response.data);
            });
        }
    };

    renderDesktopInput = (inputProps) => {
        return <Input onClick={this.autoFetchUserLocation}
                      className="locationDropdown__input-pill"
                      startAdornment={
                          <Icon className="locationDropdown__icon" onClick={this.getUserLocation}>location_on</Icon>
                      }
                      endAdornment={this.state.selectedLabel !== '' && <Icon className='locationDropdown__clear-icon'
                                                                             onClick={this.clearSelectedSuggestion}>close</Icon>}
                      placeholder={inputPlaceHolder}
                      disableUnderline={true}
                      {...inputProps}
                      value={this.state.selectedLabel}
                      onChange={(event) => {
                          this.getSuggestions(event);
                          inputProps.onChange(event)
                      }}
                      onBlur={this.hideDesktopSuggestion(inputProps)}
        />;
    };

    autoFetchUserLocation = () => {
        if (this.props.firstVisit) {
            this.getUserLocation();
        }
    }

    resetIfCurrentLocation = () => {
        if (this.state.selectedLabel === 'Current Location') {
            this._clearSearchLocation();
        }
    }

    _clearSearchLocation = () => {
        this.setState({
            selectedLabel: '',
            previousLocation: '',
            suggestions: []
        });
        this.props.onClearSearch();
    };

    renderDesktopSuggestion(inputValue, getItemProps, highlightedIndex) {
        return <Paper className="locationDropdown__paper" square>
            {this.state.suggestions.map((suggestion, index) =>
                <MenuItem
                    disabled={suggestion.disabled}
                    key={index}
                    {...getItemProps({item: suggestion})}
                    component="div"
                    selected={index === highlightedIndex}
                    className='locationDropdown__menu-item'>
                    <div className='locationDropdown__menu-item__text'>
                        {suggestion.label}
                    </div>
                </MenuItem>
            )}
        </Paper>;
    }

    hideDesktopSuggestion = (inputProps) =>
        (event) => {
            if (this.state.selectedLabel !== '') {
                this.setState({
                    sessionToken: undefined,
                    sessionTokenCreatedTime: undefined,
                });
            }
            inputProps.onBlur(event);
        };

    getSuggestions = (event) => {
        if (event.target) {
            this.setState({selectedLabel: event.target.value});
            if (event.target.value.length >= 3) {
                const apiBaseApi = Environment.apiUrl();
                let sessionToken = this.getSessionToken();
                axios.get(`${apiBaseApi}/locations/predictions?searchText=${event.target.value}&session=${sessionToken}`)
                    .then(response => {
                        if (response.data && response.data.length) {
                            this.setState({
                                suggestions: response.data.map(item => (
                                    {
                                        label: item.description,
                                        placeId: item.placeId,
                                        sessionToken: sessionToken,
                                    }
                                ))
                            })
                        } else {
                            this.setState({
                                suggestions: [{label: "No Match Found", disabled: true}]
                            });
                        }
                    }).catch(() => {
                    this.setState({
                        suggestions: [{label: "No Match Found", disabled: true}]
                    });
                });
            } else {
                this.setState({suggestions: []});
            }
        }
    };

    getSessionToken() {
        let sessionToken = this.state.sessionToken;
        if (sessionToken === undefined || this.isTokenExpired()) {
            sessionToken = uuidv4();
            this.setState({
                sessionToken: sessionToken,
                sessionTokenCreatedTime: new Date(),
            })
        }
        return sessionToken;
    }

    isTokenExpired = () => {
        const MS_IN_3_MINUTEs = 60000 * 3;
        const createdTime = this.state.sessionTokenCreatedTime;
        const diffMs = (new Date() - createdTime);

        return diffMs > MS_IN_3_MINUTEs;
    };

    showMobileSearch = () => {
        this.autoFetchUserLocation();
        this.resetIfCurrentLocation();
        this.setState({showMobileSearch: true})
    };

    hideMobileSearch = () => {
        this.setState({
            showMobileSearch: false,
            suggestions: [],
        })
    };

    renderMobileInputScreen = () => {
        return <div className="locationDropdown__container-mobile-search">
            <div className="locationDropdown__container-mobile-search-input">
                <Icon className="locationDropdown__container-mobile-search-back"
                      onClick={() => {
                          this.hideMobileSearch()
                      }}
                >keyboard_arrow_left</Icon>
                <input className='locationDropdown__container-mobile-search-input-box'
                       placeholder={inputPlaceHolder}
                       autoFocus={true}
                       value={this.state.selectedLabel}
                       onChange={this.getSuggestions}/>
            </div>
            <div className='locationDropdown__mobile-search-results'>
                {this.renderMobileSuggestions()}
            </div>
        </div>;
    };

    renderInputMobilePill() {
        return (<div>
            <div className="locationDropdown__input-mobile-pill">
                <Icon fontSize='small' className="locationDropdown__icon-mobile"
                      onClick={this.getUserLocation}>location_on</Icon>
                <span className="locationDropdown__input-mobile-pill-text"
                      onClick={this.showMobileSearch}>{inputPlaceHolder}</span>
            </div>
        </div>);
    }

    renderSelectedMobilePill = () => {
        return <div className="locationDropdown__selected-mobile-pill">
            <Icon fontSize='small' className="locationDropdown__selected-pill-icon"
                  onClick={this.getUserLocation}>location_on</Icon>
            <span
                className="locationDropdown__selected-mobile-pill-label"
                onClick={this.showMobileSearch}>{this.state.selectedLabel}</span>
            <Icon className="locationDropdown__clear-mobile-icon" onClick={this.clearSelectedSuggestion}>close</Icon>
        </div>;
    };

    renderMobileSuggestions() {
        return <div>
            {this.state.suggestions.map((suggestion) =>
                <div key={suggestion.label}
                     className={`locationDropdown__mobile-suggestion ${suggestion.disabled && 'disabled'}`}
                     onClick={() => {
                         this.setSelectedSuggestion(suggestion)
                     }}
                >{suggestion.label}</div>
            )}
        </div>;
    }

    getUserLocation = () => {
        const locationDetectionTimeoutInMs = 5000;
        const maximumTimeToStoreLocationInMs = 30000;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.handleGetPositionSuccess,
                this.props.onHandleGetLocationError, {
                    timeout: locationDetectionTimeoutInMs,
                    maximumAge: maximumTimeToStoreLocationInMs
                });
        }
    };

    handleGetPositionSuccess = (position) => {
        axios.get(`${Environment.apiUrl()}/locations/lookup`, {
            params: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            },
            headers: {
                'Session-Id': sessionStorage.getItem('connectSessionId'),
                'Page-Url': window.location.href
            }
        }).then(response => {
            this.props.onSelection({
                formattedAddress: response.data,
                geometry: {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }
            });
        }, error => {
            this.props.onSelection({
                formattedAddress: "Current Location",
                geometry: {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }
            });
        });
    };

    render = () => {
        return (
            <div className="locationDropdown">
                <div className="locationDropdown__container-desktop">
                    <Downshift
                        onSelect={(item) => this.setSelectedSuggestion(item)}
                        itemToString={(item) => item ? item.label : ''}
                    >
                        {({
                              getInputProps,
                              getItemProps,
                              inputValue,
                              highlightedIndex,
                              isOpen
                          }) => {
                            return (
                                <div>
                                    {this.renderDesktopInput(getInputProps())}
                                    {isOpen ? this.renderDesktopSuggestion(inputValue, getItemProps, highlightedIndex) : ''}
                                </div>
                            );
                        }}
                    </Downshift>
                </div>
                <div className="locationDropdown__container-mobile">
                    {this.state.selectedLabel ? this.renderSelectedMobilePill() : this.renderInputMobilePill()}
                    {this.state.showMobileSearch && this.renderMobileInputScreen()}
                </div>
            </div>
        );
    }
}

LocationDropDown.propTypes = {
    onSelection: PropTypes.func.isRequired,
    onClearSearch: PropTypes.func.isRequired,
    onHandleGetLocationError: PropTypes.func.isRequired,
    selectedLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstVisit: PropTypes.bool
};

export default LocationDropDown;