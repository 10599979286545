import React, {Component} from 'react';
import "components/programDetails/css/ProgramDetails.scss"
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import SponsoredFooter from "components/sponsored-footer/Sponsored-footer";
import GetYourRateButton from "../getYourRateButton/GetYourRateButton";
import {generateApplyUrl} from "../../non-ui-components/urlGeneration/urlGeneration";

class ProgramDetails extends Component {

    render() {
        let {program, schoolLink, showApplyButton} = this.props;

        let meritizeTrainingMbop =
            <div>{
                this.props.verbose ?
                    <div className='program-details__meritize-training'>
                        <a href='https://www.meritize.com' target="_blank" rel="noopener noreferrer"
                           className="program-details__school-link">
                            <img className='program-details__mmmbop' alt="Meritize Training"
                                 src={require('assets/images/training-logo.svg')}/>
                            Meritize Training
                        </a>**
                    </div> :
                    <div className='program-details__meritize-training'>
                        <img className='program-details__mmmbop' alt="Meritize Training"
                             src={require('assets/images/training-logo.svg')}/>
                        Meritize Training**
                    </div>
            }</div>;
        return (
            <div className="program-details__details-section-container">
                <div className='program-details__details-section'>
                    <h1 className="program-details__program-name">{program.programName}</h1>
                    <div className="program-details__school-name">
                        {program.isPartnerTraining ? meritizeTrainingMbop :
                            <React.Fragment>
                                <span className="program-details__at-school-name">at </span>
                                {schoolLink ? (<a href={schoolLink} target="_blank" rel="noopener noreferrer"
                                                  className="program-details__school-link">{program.schoolName}</a>) :
                                    program.schoolName
                                }
                            </React.Fragment>
                        }
                    </div>
                    {program.campusAddress && this.props.verbose &&
                    <div className="program-details__address-box">
                        <Icon className="program-details__details-location-icon">location_on</Icon>
                        <span className="program-details__campus-address">{program.campusAddress}</span>
                    </div>
                    }
                    {this.props.verbose && !this.props.program.isPartnerTraining &&
                    <div className='program-details__school-accreditation'>
                        <Icon className='program-details__accreditation-icon'>check_circle</Icon>
                        <span
                            className='program-details__accreditation-text'>{program.accreditation}</span>
                    </div>
                    }

                    <div className='program-details__tuition-box'>
                        <span className='program-details__tuition-label'>
                            <div className='program-details__tuition-label-text'>TUITION:</div>
                        </span>
                        <span className="program-details__tuition">{program.tuition}</span>
                    </div>
                    {this.props.verbose && showApplyButton &&
                        <div className="program-details__get-your-rate">
                            <GetYourRateButton url={generateApplyUrl(program)} />
                        </div>
                    }

                    {program.isPartnerTraining ?
                        <div className="sponsored-section-desktop">
                            <SponsoredFooter></SponsoredFooter>
                        </div> : <React.Fragment></React.Fragment>
                    }

                </div>
            </div>
        )
    }
}

ProgramDetails.defaultProps = {
    verbose: true
};

ProgramDetails.propTypes = {
    program: PropTypes.object.isRequired,
    verbose: PropTypes.bool,
    schoolLink: PropTypes.string,
    history: PropTypes.any,
    previousUrl: PropTypes.string,
};

export default ProgramDetails;