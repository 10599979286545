import React from 'react';
import 'components/howTo/css/HowTo.scss';
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import{ dropByCacheKey } from 'react-router-cache-route';

const HowTo = (props) => {
    const handleExploreClick = () => {
        dropByCacheKey('ExplorePage');

        setTimeout(() => props.history.push('/explore'), 100);
    }

    return (
        <div className="how-to__section-container">
            <div className='how-to__how-to-section'>
                <div className='how-to__how-to'>
                    <span onClick={handleExploreClick} className="how-to__link">
                        <div className={'how-to__explore ' + (props.step === 1 ? 'how-to__current-step' : '')}>
                            <div className='how-to__how-to__icon-title'>
                                <Icon className='how-to__how-to__icon'>search</Icon>
                                <div className='how-to__explore-title'>
                                    EXPLORE PROGRAMS
                                </div>
                            </div>
                            <div className='how-to__explore-description'>
                                Search for training programs that fit your life.
                            </div>
                        </div>
                    </span>

                    <div className='how-to__symbol'>
                        +
                    </div>

                    <Link to={props.contactLink} className="how-to__link">
                        <div className={'how-to__contact ' + (props.step === 2 ? 'how-to__current-step' : '')}>
                            <div className='how-to__how-to__icon-title'>
                                <Icon className='how-to__how-to__icon'>local_phone</Icon>
                                <div className='how-to__contact-title'>
                                    CONTACT PROGRAM
                                </div>
                            </div>
                            <div className='how-to__contact-description'>
                                Have someone from the school reach out to you.
                            </div>
                        </div>
                    </Link>

                    <div className='how-to__symbol'>
                        +
                    </div>

                    <a href={props.fundingLink} className="how-to__link" rel="noopener noreferrer" target="_blank">
                        <div className={'how-to__fund ' + (props.step === 3 ? 'how-to__current-step' : '')}>
                            <div className='how-to__how-to__icon-title'>
                                <Icon className='how-to__how-to__icon'>attach_money</Icon>
                                <div className='how-to__fund-title'>
                                    SECURE FUNDING
                                </div>
                            </div>
                            <div className='how-to__fund-description'>
                                Get pre-approved for a merit loan based on more than just your credit score.
                            </div>
                        </div>
                    </a>

                    <div className='how-to__symbol'>
                        =
                    </div>

                    <Link to="/" className="how-to__link">
                        <div className={'how-to__career ' + (props.step === 4 ? 'how-to__current-step' : '')}>
                            <div className='how-to__how-to__icon-title'>
                                <Icon className='how-to__how-to__icon'>work</Icon>
                                <div className='how-to__career-title'>
                                    MEANINGFUL CAREER
                                </div>
                            </div>
                            <div className='how-to__career-description'>
                                Build your path to a meaningful career.
                            </div>
                        </div>
                    </Link>

                </div>

            </div>
        </div>
    );
};

export default HowTo;

HowTo.propTypes = {
    step: PropTypes.number.isRequired,
    fundingLink: PropTypes.string,
    contactLink: PropTypes.string
};