import React from 'react';
import 'components/mobileProgramFooter/css/MobileProgramFooter.scss';
import PropTypes from "prop-types";
import SponsoredFooter from "components/sponsored-footer/Sponsored-footer";
import {generateApplyUrl} from "../../non-ui-components/urlGeneration/urlGeneration";
import GetYourRateButton from "../getYourRateButton/GetYourRateButton";

const MobileProgramFooter = (props) => {

    let meritizeTrainingMbop = <div className='mobile-program-footer__meritize-training'>
        <img className='mobile-program-footer__mmmbop' alt="Meritize Training" src={require('assets/images/training-logo.svg')}/>
        Meritize Training**</div>;
    return (
        <div className='mobile-program-footer__mobile-footer'>
            <div className='mobile-program-footer__top'>
                <div className='mobile-program-footer__top-program-name'>{props.program.programName}</div>
                <div
                    className='mobile-program-footer__top-tuition'>{props.program.tuition}</div>
            </div>
            <div className="mobile-program-footer__middle">
                <div className="mobile-program-footer__school-name">
                    {props.program.isPartnerTraining ? meritizeTrainingMbop :
                        props.program.schoolName
                    }
                </div>
                {props.program.isPartnerTraining &&
                <div className="mobile-program-footer__sponsored">
                    <SponsoredFooter/>
                </div>
                }
            </div>
            {props.showApplyButton &&
            <div className="mobile-program-footer__contact-program">
                <GetYourRateButton url={generateApplyUrl(props.program)}/>
            </div>
            }
        </div>
    );
};

export default MobileProgramFooter;

MobileProgramFooter.propTypes = {
    program: PropTypes.object.isRequired,
    showApplyButton: PropTypes.bool.isRequired,
    previousUrl: PropTypes.string,
};