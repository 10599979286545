import React from "react";
import 'components/meritizeTrainingCampus/css/MeritizeTrainingCampus.scss';
import TrainingLogo from 'assets/images/training-logo.svg'

export const MeritizeTrainingCampus = () =>
    <div>
        <img className="mmmbop"
             alt="Meritize Training"
             src={TrainingLogo}/>
        Meritize Training**
    </div>;