import React from "react";
import Rectangle from 'assets/images/rectangle.svg';
import PhoneEmail from 'assets/images/phone-email.svg';
import Calendar from 'assets/images/calendar.svg';
import Message from 'assets/images/message.svg';
import Arrow from 'assets/images/arrow.svg';
import SwirlArrow from 'assets/images/swirl-arrow.svg';
import ScrollAnimation from 'react-animate-on-scroll';
import 'components/home/css/HomeQuestions.scss'

export const HomeQuestions = () =>
    <>
        <div className='home-questions'>
            <img className='home__rectangle' src={Rectangle} alt=''/>
            <h2 className='home-questions__title'>Get your questions answered</h2>
            <div className='home-questions__items'>
                <ScrollAnimation animateIn="fadeIn" duration={1} animateOnce={true}>
                    <div className='home-questions__item'>
                        <span className='home-questions__number'>1</span>
                        <span className="home-questions__item-description-left">
                            Express your interest in a program
                        </span>
                        <img src={PhoneEmail} alt='' className='home-questions__item-image'/>
                    </div>
                    <img src={Arrow} className='home-questions__item-image arrow' alt=''/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn" duration={1.5} animateOnce={true}>
                    <div className='home-questions__item'>
                        <img src={Calendar} alt='' className='home-questions__item-image'/>
                        <span className="home-questions__item-description">We’ll make sure the school gets your contact information</span>
                        <span className='home-questions__number'>2</span>
                    </div>
                    <img src={SwirlArrow} className='home-questions__item-image swirl-arrow' alt=''/>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeIn" duration={1.75} animateOnce={true}>
                    <div className='home-questions__item'>
                        <span className='home-questions__number'>3</span>
                        <span className="home-questions__item-description-left">A school representative will reach out to you</span>
                        <img src={Message} alt='' className='home-questions__item-image'/>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
        <div className='home-questions-mobile'>
            <img className='home__rectangle' src={Rectangle} alt=''/>
            <h1 className='home-questions__title'>Get your questions answered</h1>
            <div className='home-questions__items'>
                <div className='home-questions__item'>
                    <span className='home-questions__number'>1</span>
                    <span className="home-questions__item-description-left">
                            Express your interest in a program
                        </span>
                </div>
                <div className='home-questions__item-image-container'>
                    <img src={Arrow} className='home-questions__item-image-arrow' alt=''/>
                    <img src={PhoneEmail} alt='' className='home-questions__item-image'/>
                </div>
                <div className='home-questions__item--middle'>
                    <span className="home-questions__item-description">We’ll make sure the school gets your contact information</span>
                    <span className='home-questions__number'>2</span>
                </div>
                <div className='home-questions__item-image-container'>
                    <img src={Calendar} alt='' className='home-questions__item-image'/>
                    <img src={SwirlArrow} className='home-questions__item-image-arrow' alt=''/>
                </div>
                <div className='home-questions__item'>
                    <span className='home-questions__number'>3</span>
                    <span className="home-questions__item-description-left">A school representative will reach out to you</span>
                </div>
                <div className='home-questions__item-image-container--last'>
                    <img src={Message} alt='' className='home-questions__item-image--last'/>
                </div>
            </div>
        </div>
    </>;