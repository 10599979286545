import React, {Component} from "react";
import "animate.css/animate.min.css";
import 'pages/pageNotFoundPage/css/PageNotFoundPage.scss';
import PageNotFoundDesktop from 'assets/images/page-not-found-desktop.svg';
import PageNotFoundMobile from 'assets/images/page-not-found-mobile.svg';

import {Helmet} from "react-helmet";
import Footer from "components/footer/Footer";
import Fab from "@material-ui/core/Fab";

class PageNotFoundPage extends Component {

    redirectToExplorePage = () => {
        this.props.history.push({
            pathname: '/explore'
        });
    }

    render = () => {
        window.scrollTo(0, 0);

        return (
            <React.Fragment>
                <Helmet>
                    <title>Meritize Connect - Oops</title>
                    <meta name="description"
                          content="It takes a lot to get to where you want to be. Start here: explore training options, contact programs you’re interested in, and apply for funding all in one place."/>
                    <link rel="canonical" href="https://www.meritizeconnect.com"/>
                </Helmet>

                <div className='page-not-found-page--mobile'>
                    <div className='page-not-found-page__container'>
                        <h1 className='page-not-found-page__title'>
                            Oops...<br/>
                            Looks like a 404 error
                        </h1>
                        <div className='page-not-found-page__subheader'>
                            We've taken note, and our software developers are on the case.
                        </div>
                        <div className='page-not-found-page__sub-paragraph'>Curious why this error happened? Love to
                            solve problems? You might enjoy a career as a software developer!
                        </div>
                        <Fab className='page-not-found-page__explore-button'
                             onClick={this.redirectToExplorePage}
                        >
                            EXPLORE PROGRAMS
                        </Fab>
                    </div>
                    <img src={PageNotFoundMobile} className='page-not-found-page__image' alt=''/>
                </div>
                <div className='page-not-found-page--desktop'>
                    <div className='page-not-found-page'>
                        <div className='page-not-found-page__container'>
                            <h1 className='page-not-found-page__title'>
                                Oops...<br/>
                                Looks like a 404 error
                            </h1>
                            <div className='page-not-found-page__subheader'>
                                We've taken note, and our software developers are on the case.
                            </div>
                            <div className='page-not-found-page__sub-paragraph'>
                                Curious why this error happened? Love to solve problems? You might enjoy a career as a software developer!
                            </div>

                            <Fab className='page-not-found-page__explore-button'
                                 onClick={this.redirectToExplorePage}
                            >
                                EXPLORE PROGRAMS
                            </Fab>
                        </div>
                        <img src={PageNotFoundDesktop} className='page-not-found-page__image' alt=''/>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        );
    };
};

export default PageNotFoundPage;