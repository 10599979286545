import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import "./css/OnlineCheckbox.scss"

export const OnlineCheckbox = ({handleToggle, isOnline}) =>
    <div
        className='online-checkbox'
        onClick={handleToggle}>
        <Checkbox
            className='online-checkbox__checkbox'
            checked={isOnline}/>
        <div
            className={isOnline ?
                "online-checkbox__text-checked" :
                "online-checkbox__text-unchecked"}
        >
            <span className='online-checkbox__text'>Online Only</span>
        </div>
    </div>;