const strToBool = (s) => {
  return /^(true|yes|on|1)$/.test((s || '').toLowerCase());
}

export class EnvironmentConfig {
  constructor(window, process_env_func) {
    this.window = window;
    // We use a function here because there's some magic behind process.env that prohibits just saving
    // the value of `process`. We need to access `env` lazily so we can mock it in some tests.
    this.process_env_func = process_env_func;
  }

  envConf(name, reactAppName) {
    return this.window.environmentConfig ?
      this.window.environmentConfig[name] :
      this.process_env_func()[reactAppName];
  }

  requiredEnvConf(name, reactAppName) {
    const value = this.envConf(name, reactAppName);
    if (!value) {
      throw new Error(`${name} must be configured, either through window.environmentConfig or ${reactAppName}.`);
    }
    return value;
  }

  apiUrl() { return this.requiredEnvConf('API_URL', 'REACT_APP_API_URL'); }
  applyUrl() { return this.requiredEnvConf('APPLY_URL', 'REACT_APP_APPLY_URL'); }

  redirectToMaintenance() { return strToBool(this.envConf('redirectToMaintenance', 'REACT_APP_REDIRECT_TO_MAINTENANCE')); }
  announcementMessage() { return this.envConf('announcementMessage', 'REACT_APP_ANNOUNCEMENT_MESSAGE'); }
  upcomingMaintenanceDatetime() { return this.envConf('UPCOMING_MAINTENANCE_DATETIME', 'REACT_APP_UPCOMING_MAINTENANCE_DATETIME'); }
  sentryDsn()         { return this.envConf('SENTRY_DSN',         'REACT_APP_SENTRY_DSN');         }
  sentryEnvironment() { return this.envConf('SENTRY_ENVIRONMENT', 'REACT_APP_SENTRY_ENVIRONMENT'); }
  wufooUrl()          { return this.envConf('WUFOO_URL', 'REACT_APP_WUFOO_URL'); }
  wufooProgramIDField() { return this.envConf('WUFOO_PROGRAM_ID_FIELD', 'REACT_APP_WUFOO_PROGRAM_ID_FIELD'); }
  wufooPromoField()   { return this.envConf('WUFOO_PROMO_FIELD', 'REACT_APP_WUFOO_PROMO_FIELD'); }
  gtmParams()         { return this.envConf('GTM_PARAMS', 'REACT_APP_GTM_PARAMS'); }
  googleMapsApi()     { return this.envConf('GOOGLE_MAPS_API', 'REACT_APP_GOOGLE_MAPS_API'); }
}

let Environment = new EnvironmentConfig(window, () => process.env);

export default Environment;
