import Environment from "util/Environment";

export const generateApplyUrl = (program) => {
    if (program.promoCode) {
        return `${Environment.applyUrl()}/?promo=${encodeURI(program.promoCode)}`;
    }
    return `${Environment.applyUrl()}/?pc=${encodeURI(program.stableId)}`;
}

export const generateContactLink = (program) => {
    return program.promoCode ?
        `/contact/${program.id}/?promo=${encodeURI(program.promoCode)}` :
        `/contact/${program.id}/`;
}