import React from 'react';
import PropTypes from 'prop-types';
import 'components/toast/css/Toast.scss'
import {Snackbar} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {withStyles} from "@material-ui/styles";

const styles = {
    "@global": {
        ".MuiSnackbarContent-root": {
            backgroundColor: "#2F78D5",
            flexWrap: "unset"
        },
        ".MuiSnackbarContent-action": {
            paddingLeft: "0"
        }
    }
};

export const ToastMessage = (props) => {
    const Icon = props.icon;
    return <div id="message-id" className={'message'}>
        <Icon className='toast__icon'/>
        <span dangerouslySetInnerHTML={{ __html: props.message }} />
    </div>;
};

export const Toast = (props) => {
    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            key={props.message}
            open={props.open}
            onClose={props.onClose}
            ClickAwayListenerProps={{
                mouseEvent: false,
                touchEvent: false
            }}
            autoHideDuration={props.timeout}
            message={
                <ToastMessage
                    message={props.message}
                    icon={props.icon}
                />
            }
            action={[
                <IconButton key="close"
                            aria-label="close"
                            color="inherit"
                            className={'closeIcon'}
                            onClick={props.onClose}>
                    <CloseIcon/>
                </IconButton>,
            ]}
        />
    );
};

Toast.propTypes = {
    icon: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(Toast);