import * as React from "react";
import 'components/mapListButton/css/MapListButton.scss';
import Fab from "@material-ui/core/Fab";
import ListIcon from '@material-ui/icons/List';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';

export const MapListButton = (props) => {
        const Icon = props.showMap ? ListIcon : MapOutlinedIcon;
        const buttonText = props.showMap ? 'List' : 'Map';
        return <Fab className='map-list-button__pill' onClick={props.clickButton}>
            <Icon className='map-list-button__icon'/>
            {buttonText}
        </Fab>
};
