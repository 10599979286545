import React, {Component} from 'react';
import "pages/maintenancePage/css/MaintenancePage.scss";
import toolbox from 'assets/images/toolbox.svg';

class MaintenancePage extends Component {

    render() {
        return (
            <div className="maintenance-page__container">
                <img className="maintenance-page__toolbox-logo" alt="Site Under Maintenance" src={ toolbox } />
                <div className="maintenance-page__header">We’re upgrading our tools…</div>
                <div className="maintenance-page__message">We are currently undergoing scheduled maintenance. We will be back shortly. Thank you for your patience!</div>
            </div>
        );
    }
};


export default MaintenancePage;