import Environment from "util/Environment";
import React, {Component} from 'react';
import 'pages/contactProgramPage/css/ContactProgramsPage.scss'
import ProgramDetails from "components/programDetails/ProgramDetails";
import HowTo from "components/howTo/HowTo";
import axios from "axios";
import MobileProgramFooter from "components/mobileProgramFooter/MobileProgramFooter";
import {Helmet} from "react-helmet";
import Footer from "components/footer/Footer";
import ContactProgramBreadcrumb from "components/contactProgramBreadcrumb/ContactProgramBreadcrumb";
import PropTypes from "prop-types";
import {generateApplyUrl} from "../../non-ui-components/urlGeneration/urlGeneration";

class ContactProgramPage extends Component {

    state = {
        program: {}
    };

    wufoo = () => {
        const programId = this.props.match.params.id;
        const params = new URLSearchParams(!!this.props.location ? this.props.location.search : undefined);
        const promoCode = params.get("promo");
        return {
            __html: `<iframe height="1175" title="Embedded Wufoo Form" ` +
                `allowTransparency="true" frameBorder="0" scrolling="no" style="width:100%;border:none" ` +
                `src="${Environment.wufooUrl()}def/` +
                `${Environment.wufooProgramIDField()}=${programId}` +
                `${this.getPromo(promoCode)}">` +
                `<a href="${Environment.wufooUrl()}">Fill out my Wufoo form!</a></iframe>`
        }
    };

    getPromo = (promoCode) => {
        if (promoCode) {
            return `&${Environment.wufooPromoField()}=${encodeURI(promoCode)}`;
        }
        return '';
    };

    componentDidMount() {
        const {match: {params}} = this.props;
        const apiBaseApi = Environment.apiUrl();
        axios.get(apiBaseApi + `/programs/${params.id}`, {
            headers: {
                'Session-Id': sessionStorage.getItem('connectSessionId'),
                'Page-Url': window.location.href
            }
        }).then(response => {
            if (response.data.id !== parseInt(params.id)) {
                this.props.history.push({pathname: '/explore'});
            }
            this.setState({program: response.data})
        }).catch(error => {
            this.props.history.push({pathname: '/404'});
        });
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0)
    }

    generateContactLink(program) {
        return program.promoCode ?
            `/contact/${program.id}/?promo=${encodeURI(program.promoCode)}` :
            `/contact/${program.id}/`;
    }

    getPageDescription() {
        if (this.state.program.programDescription == null) {
            return `${this.state.program.programName} at ${this.state.program.schoolName} in ${this.state.program.campusAddress}`;
        }
        return this.state.program.programDescription;
    }

    render() {
        let previousUrl = this.props.location.previousUrl || '/explore';
        return (
            <React.Fragment>
                <div className="contact-program">
                    <ContactProgramBreadcrumb
                        history={this.props.history}
                        programId={this.state.program.id}
                        previousUrl={previousUrl}
                    />
                    {!this.state.program.programName &&
                    <div className='contact-program-page__empty'></div>
                    }
                    {this.state.program.programName &&

                    <React.Fragment>
                        <ProgramDetails program={this.state.program} verbose={false}/>
                        <Helmet>
                            <meta charSet="utf-8"/>
                            <title>Contact: {this.state.program.programName} - {this.state.program.schoolName} - {this.state.program.campusAddress}</title>
                            <meta name="description" content={this.getPageDescription()}/>
                            <link rel="canonical"
                                  href={`https://www.meritizeconnect.com/contact/${this.state.program.id}`}/>
                        </Helmet>
                    </React.Fragment>
                    }
                    <div className="contact-program-page__wufoo-section-container">
                        <div className='contact-program-page__wufoo-section'>
                            <div dangerouslySetInnerHTML={this.wufoo()}/>
                        </div>
                    </div>

                    <HowTo
                        step={2}
                        history={this.props.history}
                        fundingLink={generateApplyUrl(this.state.program)}
                        contactLink={this.generateContactLink(this.state.program)}
                    />

                    <div className='program-details-page__mobile-footer'>
                        <MobileProgramFooter program={this.state.program} showApplyButton={false} previousUrl={previousUrl}/>
                    </div>
                </div>
                <Footer/>
                <div className='contact-program-page__bottom-margin'/>
            </React.Fragment>
        );
    }
};

ContactProgramPage.propTypes = {
    previousUrl: PropTypes.string,
};

export default ContactProgramPage;