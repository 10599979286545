import Environment from "util/Environment";
import React, {Component} from 'react';
import axios from "axios"
import "pages/explorePage/css/ExplorePage.scss"
import LocationDropdown from "components/locationDropdown/LocationDropdown";
import OnlineLocationPill from "components/onlineLocationPill/OnlineLocationPill";
import {Map} from "components/map/Map";
import {MapListButton} from "components/mapListButton/MapListButton";
import {ProgramList} from "components/programList/ProgramList";
import {Pagination} from "components/pagination/Pagination";
import Toast from "components/toast/Toast";
import LocationIcon from '@material-ui/icons/LocationOn';
import {OnlineCheckbox} from "components/onlineCheckbox/OnlineCheckbox";
import {Helmet} from "react-helmet";
import MobileLocationDrawer from "components/mobileLocationDrawer/MobileLocationDrawer";
import Footer from "components/footer/Footer";
import {DynamicSearch} from "components/dynamicSearch/DynamicSearch";

class ExplorePage extends Component {
    state = {
        programs: [],
        displayedPrograms: [],
        pageNumber: 0,
        totalPages: undefined,
        showMap: false,
        isOnline: false,
        isNonCachingBrowser: false,
        cardIdViewed: undefined,
        lat: null,
        lng: null,
        highlightedProgram: undefined,
        programListTitleHelper: '',
        location: '',
        term: undefined,
        openSnackBar: false,
        mapProgramParams: undefined,
        selectedMarkerProgram: undefined,
        key: undefined,
    };

    constructor(props) {
        super(props);
        if (!!props.cacheLifecycles) {
            props.cacheLifecycles.didRecover(this.componentDidRecover);
        }
        this.filterContainerRef = React.createRef();
    }

    componentDidRecover = () => {
        if (this.state.isNonCachingBrowser && !!this.state.cardIdViewed) {
            document.getElementById(this.state.cardIdViewed).scrollIntoView();
            window.scrollBy(0, -100);
        }
    };

    componentDidMount = () => {
        this.reloadState(this.loadPrograms);
    };

    componentWillUnmount = () => {
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.location.search !== prevProps.location.search) {
            this.reloadState(this.getPrograms);
        }
    };

    reloadState(execute) {
        const params = new URLSearchParams(!!this.props.location ? this.props.location.search : undefined);

        this.setState({
            isNonCachingBrowser: (window.navigator.userAgent.indexOf("CriOS") > -1),
            location: params.get('location') || '',
            lat: parseFloat(params.get('lat')) || null,
            lng: parseFloat(params.get('lng')) || null,
            term: params.get('term') || undefined,
            pageNumber: parseInt(params.get('pageNumber')) - 1 || 0,
            isOnline: params.get('onlineOnly') === 'true',
            showMap: params.get('viewMode') === 'Map'
        }, execute);
    }

    setLocation = (data) => {
        this.setState({
                pageNumber: 0,
                lat: data.geometry.location.lat,
                lng: data.geometry.location.lng,
                location: data.formattedAddress,
            },
            () => {
                this.pushHistory();
                this.loadPrograms();
            }
        );
    };

    clearLocation = () => {
        this.setState({
            pageNumber: 0,
            location: '',
            lat: undefined,
            lng: undefined
        }, () => {
            this.loadPrograms();
            this.pushHistory();
        });
    };

    setSearchTerm = (data) => {
        this.setState({
                pageNumber: 0,
                term: data,
            },
            () => {
                this.pushHistory();
                this.loadPrograms();
            }
        );
    };

    clearSearchTerm = () => {
        this.setState({
                pageNumber: 0,
                term: '',
            },
            () => {
                this.pushHistory();
                this.loadPrograms();
            }
        );
    };

    toggleIsOnline = () => {
        this.setState({
                pageNumber: 0,
                lat: undefined,
                lng: undefined,
                location: '',
                isOnline: !this.state.isOnline
            },
            () => {
                this.pushHistory();
                this.loadPrograms();
            });
    };

    pushHistory = () => {
        if (!!this.props.history) {
            this.props.history.push(
                {
                    pathname: '/explore',
                    search: `onlineOnly=${this.state.isOnline}` +
                        (!!this.state.location ? `&location=${this.state.location}` : '') +
                        (!!this.state.lat ? `&lat=${this.state.lat}` : '') +
                        (!!this.state.lng ? `&lng=${this.state.lng}` : '') +
                        (!!this.state.term ? `&term=${this.state.term}` : '') +
                        (!!this.state.showMap ? '&viewMode=Map' : '') +
                        (!!this.state.pageNumber ? `&pageNumber=${this.state.pageNumber + 1}` : '')
                });
        }
    };

    loadPrograms = () => {
        this.setState({totalPages: undefined}, () => {
            this.getPrograms();
        })
    };

    isLoading = () => {
        return this.state.totalPages === undefined;
    };

    getPrograms = () => {
        axios.get(`${Environment.apiUrl()}/programs/search`, {
            params: {
                pageNumber: this.state.pageNumber,
                lat: !!this.state.lat ? this.state.lat : undefined,
                lng: !!this.state.lng ? this.state.lng : undefined,
                isOnline: this.state.isOnline,
                term: this.state.term ? this.state.term : undefined
            },
            headers: {
                'Session-Id': sessionStorage.getItem('connectSessionId'),
                'Page-Url': window.location.href
            }
        }).then(response => {
            if (!this.state.cardIdViewed) {
                this.filterContainerRef && this.filterContainerRef.current.scrollIntoView();
                window.scrollTo(0, 0);
            }

            const searchLocation = this.state.lat && this.state.lng && {
                latitude: this.state.lat,
                longitude: this.state.lng
            };

            this.setState({
                programs: response.data.content,
                displayedPrograms: response.data.content,
                totalPages: response.data.totalPages,
                programListTitleHelper: searchLocation ? 'within 100 miles' : '',
                mapProgramParams: {
                    programs: response.data.content,
                    searchLocation: searchLocation,
                    isOnline: this.state.isOnline
                },
                cardIdViewed: null,
            });

            if (this.state.markerClickPrograms) this.handleSelectedMarkerClick(this.state.markerClickPrograms);
        }, error => {
        });
    };

    handlePreviousPageClick = () => {
        this.setState({
                pageNumber: this.state.pageNumber - 1,
                key: Math.random()
            },
            () => {
                this.pushHistory();
                this.getPrograms();
            }
        );
    };

    handleNextPageClick = () => {
        this.setState({
                pageNumber: this.state.pageNumber + 1,
                key: Math.random()
            },
            () => {
                this.pushHistory();
                this.getPrograms();
            }
        );
    };

    handleCardClick = (program) => {
        this.setState({
            cardIdViewed: program.id
        })
    };

    pickMapOrList = () => {
        this.setState({
            showMap: !this.state.showMap
        }, () => {
            this.pushHistory();
        })
    };

    handleCardEnter = (program) => {
        this.setState({
            highlightedProgram: program
        })
    };

    handleCardLeave = () => {
        this.setState({
            highlightedProgram: undefined
        })
    };

    handleSnackBarOpen = () => {
        this.setState({openSnackBar: true});
    };

    handleSnackBarClose = () => {
        this.setState({openSnackBar: false});
    };

    handleSelectedMarkerClick = (programs) => {
        let selectedPrograms = [];
        this.state.programs.forEach((program) => {
            let match = programs.find((p) => {
                return p.id === program.id
            });
            if (match) selectedPrograms.push(match);
        });

        this.setState({
            displayedPrograms: selectedPrograms,
            markerClickPrograms: programs,
            selectedMarkerProgram: programs[0],
            programListTitleHelper: <BackToResults clearSelectedMarker={this.clearSelectedMarker}/>
        });
    };

    clearSelectedMarker = () => {
        this.setState({
            displayedPrograms: this.state.programs,
            markerClickPrograms: undefined,
            selectedMarkerProgram: undefined,
            programListTitleHelper: (this.state.lat && this.state.lng) ? 'within 100 miles' : ''
        });
    };

    render = () => {
        return (
            <React.Fragment>
                <div className="explore-page__container">
                    <Helmet>
                        <title>Meritize Connect - Find training that fits your life</title>
                        <meta name="description"
                              content="Find training that fits your life: See your closest options on a map and find one that fits your commute. Compare costs of programs and find one that fits your budget. Look at start dates and durations and find one that fits your timeline."/>
                        <link rel="canonical" href="https://www.meritizeconnect.com/explore"/>
                    </Helmet>
                    <div className="explore-page__programs">
                        <div ref={this.filterContainerRef} className="explore-page__filter-container">
                            <div className="explore-page__filters">
                                <div className="explore-page__filters-map-controls">
                                    <div className='explore-page__filters-category-container'>
                                        <DynamicSearch
                                            setSearchTerm={this.setSearchTerm}
                                            clearSearchTerm={this.clearSearchTerm}
                                            term={this.state.term}
                                        />
                                    </div>
                                    <MapListButton clickButton={this.pickMapOrList} showMap={this.state.showMap}/>
                                </div>
                                <div className="explore-page__filters-categories">
                                    {this.state.isOnline ?
                                        <OnlineLocationPill/> :
                                        <LocationDropdown
                                            firstVisit={false}
                                            onSelection={this.setLocation}
                                            onClearSearch={this.clearLocation}
                                            onHandleGetLocationError={this.handleSnackBarOpen}
                                            selectedLocation={this.state.location}
                                            key={this.state.key}
                                        />
                                    }
                                    <OnlineCheckbox handleToggle={this.toggleIsOnline} isOnline={this.state.isOnline}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`explore-page__card-container${this.isLoading() ? '--loading' : ''} ${this.state.showMap && 'hidden-mobile'}`}>
                            <ProgramList
                                programs={this.state.displayedPrograms}
                                isLoading={this.isLoading()}
                                titleHelperComponent={this.state.programListTitleHelper}
                                handleCardClick={this.handleCardClick}
                                handleCardEnter={this.handleCardEnter}
                                handleCardLeave={this.handleCardLeave}
                                history={this.props.history}
                            />
                            {!this.state.markerClickPrograms && this.state.totalPages > 0 && <Pagination
                                pageNumber={this.state.pageNumber + 1}
                                totalPages={this.state.totalPages}
                                handlePreviousPageClick={this.handlePreviousPageClick}
                                handleNextPageClick={this.handleNextPageClick}
                            />}
                        </div>
                    </div>
                    <div className={`explore-page__map ${!this.state.showMap ? 'hidden-mobile' : ''}`}>
                        <Map
                            showMap={this.state.showMap}
                            highlightedProgram={this.state.highlightedProgram}
                            mapProgramParams={this.state.mapProgramParams}
                            onMarkerClick={this.handleSelectedMarkerClick}
                            onClearMarkerClick={this.clearSelectedMarker}
                            selectedMarkerProgram={this.state.selectedMarkerProgram}
                        />
                    </div>
                    <Toast
                        icon={LocationIcon}
                        open={this.state.openSnackBar}
                        onClose={this.handleSnackBarClose}
                        timeout={5000}
                        message={'We are unable to retrieve your location. Please check your settings.'}
                    />
                    {this.state.markerClickPrograms &&
                    <div className='explore-page__program-drawer--mobile'>
                        <MobileLocationDrawer programs={this.state.markerClickPrograms}
                                              searchedByLocation={!!this.state.location}
                        />
                    </div>
                    }
                </div>
                {!this.state.showMap &&
                <Footer/>
                }
            </React.Fragment>
        );
    }
}

export const BackToResults = (props) => (
    <div className='program-list__back-to-results' onClick={props.clearSelectedMarker}>
        Back to results
    </div>);

export default ExplorePage;
