import * as React from "react";
import Fab from "@material-ui/core/Fab";
import 'components/pagination/css/Pagination.scss'

export const Pagination = (props) => (
    <div className='pagination'>
        <Fab className='pagination__previous-button'
             onClick={props.handlePreviousPageClick}
             disabled={(props.pageNumber === 1)}
        >
            <i className="material-icons">chevron_left</i>
        </Fab>
        <div className="pagination__page-number">
            {props.pageNumber} of {props.totalPages}
        </div>
        <Fab className='pagination__next-button'
             onClick={props.handleNextPageClick}
             disabled={(props.pageNumber === props.totalPages)}>
            <i className="material-icons">chevron_right</i>
        </Fab>
    </div>);

