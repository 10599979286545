import * as React from "react";
import Card from "components/card/Card";
import 'components/programList/css/ProgramList.scss';

export const ProgramList = (props) => (
    <React.Fragment>
        <div className="program-list__header-container">
            <h1 className='program-list__header'>Your Training Options</h1>
            <span className='program-list__title-helper'>{props.titleHelperComponent}</span>
        </div>
        {props.programs.length > 0 &&
        <div className='program-list__card-container'>
            {props.programs.map((program) =>
                (<div key={program.id}
                      id={program.id}
                      className="program-list__item"
                      onClick={() => props.handleCardClick(program)}
                      onMouseEnter={() => props.handleCardEnter(program)}
                      onFocus={() => props.handleCardEnter(program)}
                      onMouseLeave={props.handleCardLeave}
                >
                    <Card program={program} history={props.history}/>
                </div>))
            }
        </div>
        }
        {props.programs.length === 0 && !props.isLoading &&
        <div className='program-list__no-programs-container'>
            <img className="program-list__no-programs-logo" alt="No programs meet your criteria."
                 src={require('assets/images/empty-program-list.svg')}/>
            <div className="program-list__no-programs-header">No programs meet your criteria.</div>
            <div className="program-list__no-programs-message">Try adjusting your search to display more results.</div>
        </div>
        }
    </React.Fragment>
);