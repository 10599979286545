import React from "react";
import "components/getYourRateButton/css/GetYourRateButton.scss"
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";

const GetYourRateButton = (props) => {
    let analyticsClass = 'analytics-button-get-your-rate';

    return (
        <div className="program-details-page__rate-button-container">
            <a className={"program-details-page__rate-button-link " + analyticsClass}
               href={props.url} target="_blank"
               rel="noopener noreferrer">
                <Fab
                    classes={{label: analyticsClass}}
                    disableRipple={true}
                    variant="extended"
                    size="large"
                    aria-label="Add"
                    className={"program-details-page__rate-button " + analyticsClass}
                >
                    GET YOUR RATE
                </Fab>
            </a>
        </div>
    );
};

GetYourRateButton.propTypes = {
    url: PropTypes.string.isRequired,
};

export default GetYourRateButton;
