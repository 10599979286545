import React from "react";
import 'components/markerLocationInformation/css/markerLocationInformation.scss';
import {MeritizeTrainingCampus} from "components/meritizeTrainingCampus/MeritizeTrainingCampus";

const getCategories = (programs) => (new Set(programs.map(program => program.industryCategory)));

export const MarkerLocationInformation = (props) => {
    const categoryLength = getCategories(props.programs).size;
    const programOrPrograms = props.programs.length > 1 ? 'programs' : 'program';
    const numberOfAdditionalIndustries = categoryLength > 1 ? `, +${categoryLength - 1}` : '';
    const isMeritizeTraining = props.programs.filter(program => program.isPartnerTraining).length > 0;

    return (<div className='marker-location-information'>
        <span className='marker-location-information__inline-container'>
          <span className='marker-location-information__school-name'>
            {isMeritizeTraining ? <MeritizeTrainingCampus/> : props.programs[0].schoolName}
          </span>
          <span className='marker-location-information__location'>
              {props.searchLocation ? props.programs[0].location : ''}
          </span>
        </span>
        <div className='marker-location-information__industry'>
            {props.programs[0].industryCategory}{numberOfAdditionalIndustries}
        </div>
        <div className='marker-location-information__program-count'>
            {props.programs.length} {programOrPrograms} available at this location
        </div>
    </div>);
};