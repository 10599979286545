import React from 'react';
import PropTypes from "prop-types";
import "components/mobileLocationDrawer/css/MobileLocationDrawer.scss"
import Sponsored from "components/sponsored/Sponsored";
import {Link} from "react-router-dom";
import {MeritizeTrainingCampus} from "components/meritizeTrainingCampus/MeritizeTrainingCampus";

const MobileLocationDrawer = (props) => {

    let isMeritizeTraining = props.programs.filter(program => program.isPartnerTraining).length > 0;

    const getProgramDetailsURL = (program) => {
        let params = (!!program.programName ? program.programName.replace(/ /g, "-") : "")
            + '-' + (!!program.schoolName ? program.schoolName.replace(/ /g, "-") : "")
            + '-' + (!!program.location ? program.location.replace(/,/g, "").replace(/ /g, "-") : "");

        return (`/program/${program.id}/programinfo/${params}`);
    };

    return (
        <div className='mobile-program-drawer'>
            <div className='mobile-program-drawer__header'>
                <div className='mobile-program-drawer__title-row'>
                    <div className='mobile-program-drawer__title'>
                        {isMeritizeTraining ? <MeritizeTrainingCampus/> : props.programs[0].schoolName}
                    </div>
                    {props.searchedByLocation &&
                    <div className='mobile-program-drawer__distance'>{props.programs[0].location}</div>}
                </div>
                <div className='mobile-program-drawer__program-count'>
                    {props.programs.length} {props.programs.length === 1 ? 'program ' : 'programs '}
                    available at this location
                </div>
            </div>
            <div className='mobile-program-drawer__programs-container'>
                {props.programs.map((program) =>
                    <Link
                        key={program.id}
                        className='mobile-program-drawer__program-container'
                        to={getProgramDetailsURL(program)}
                    >
                        <div className='mobile-program-drawer__program'>{program.programName}</div>
                        <div className='mobile-program-drawer__tuition-container'>
                            {!program.isPartnerTraining &&
                            <div className='mobile-program-drawer__tuition'>{program.tuition}</div>
                            }
                            {program.isPartnerTraining &&
                            <div className='mobile-program-drawer__sponsored'>
                                <Sponsored key={program.id}/>
                            </div>
                            }
                        </div>
                    </Link>
                )}
            </div>
        </div>);
};

export default MobileLocationDrawer;

MobileLocationDrawer.propTypes = {
    programs: PropTypes.array.isRequired,
    searchedByLocation: PropTypes.bool
};

MobileLocationDrawer.defaultProps = {
    searchedByLocation: false
}