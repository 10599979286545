import axios from "axios";
import React from "react";
import MaintenancePage from "pages/maintenancePage/MaintenancePage";
import {CacheRoute, CacheSwitch} from "react-router-cache-route";
import ExplorePage from "pages/explorePage/ExplorePage";
import HomePage from "pages/homePage/HomePage";
import ProgramDetailsPage from "pages/programDetailsPage/ProgramDetailsPage";
import ContactProgramPage from "pages/contactProgramPage/ContactProgramPage";
import {Route} from "react-router-dom";
import PageNotFoundPage from "./pages/pageNotFoundPage/PageNotFoundPage";
import Environment from "util/Environment";

export const NoMatch = (props) => {
    const routes = ['/explore', '/program/', '/contact/', '/event/'];
    const matchedRoutes = routes.filter(route => props.location.pathname.startsWith(route));
    const isExistingRoute = matchedRoutes.length || props.location.pathname === '/';

    if (isExistingRoute) {
        return '';
    }

    return <PageNotFoundPage {...props}/>;
};

export class AppRouter extends React.Component {
    savePageChange = () => {
        const doNotTrack = document.cookie.replace(/(?:(?:^|.*;\s*)doNotTrack\s*=\s*([^;]*).*$)|^.*$/, "$1");
        const currentLocation = window.location.href;
        doNotTrack || setTimeout(() => axios.get(`${Environment.apiUrl()}/pageview`, {
            headers: {
                'Pragma': 'no-cache',
                'Session-Id': sessionStorage.getItem('connectSessionId'),
                'Page-Url': currentLocation
            }
        }), 0);
    };

    componentDidMount = () => {
        this.props.history.listen(this.savePageChange);
        this.savePageChange();
    };

    render = () =>
        Environment.redirectToMaintenance() ?
            <MaintenancePage/> :
            <CacheSwitch>
                <CacheRoute exact path={['/explore']} component={ExplorePage} cacheKey="ExplorePage"/>
                <Route exact path={['/', '/event/*']} component={HomePage}/>
                <Route path={['/program/:id']} component={ProgramDetailsPage}/>
                <Route path={['/contact/:id']} component={ContactProgramPage}/>
                <Route exact path={'public/sitemap.xml'}/>
                <Route component={PageNotFoundPage}/>
                <Route exact path={'/404'} component={PageNotFoundPage}/>
            </CacheSwitch>;
}