import React from "react";
import "components/ctaButton/css/CTAButton.scss"
import PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";

const CTAButton = (props) => {
    let {style, url, text, analyticsClass} = props;
    switch (style) {
        case 'primary-dark': break;
        case 'primary-light': break;
        case 'secondary-dark': break;
        default:
            style = 'primary-dark';
    };

    return (
        <div className="CTAButton-container">
            <a href={url} target="_blank" rel="noopener noreferrer" className={analyticsClass}>
                <Fab
                    classes={{label: analyticsClass}}
                    disableRipple={true}
                    variant="extended"
                    size="large"
                    aria-label="Add"
                    className={"CTAButton " + style + ' ' + analyticsClass}
                >
                    {text}
                </Fab>
            </a>
        </div>
    );
};

CTAButton.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    style: PropTypes.string,
    analyticsClass: PropTypes.string,
};

export default CTAButton;
