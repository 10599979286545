import Environment from "util/Environment";
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import 'components/map/css/Map.scss';
import CampusMarker from 'assets/images/campus-marker.svg';
import CampusHoverMarker from 'assets/images/campus-marker-hover.svg';
import SearchLocationMarker from 'assets/images/search-location-marker.svg';
import {MarkerLocationInformation} from "components/markerLocationInformation/markerLocationInformation";

export class Map extends React.Component {
    state = {
        map: undefined,
        markers: [],
        scaledSize: undefined,
        defaultLocation: undefined,
        highlightedProgram: undefined,
        mapProgramParams: undefined,
        searchLocationMarkerIcon: undefined,
        showMap: undefined,
        mapListenerAdded: false,
        selectedMarkerProgram: undefined,
        clusterer: undefined
    };

    static getDerivedStateFromProps = (props, state) => {
        const {mapProgramParams} = props;

        if (state.map && !state.mapListenerAdded) {
            state.map.addListener('click', () => {
                props.onClearMarkerClick();
            });
            state.mapListenerAdded = true;
        }

        if (state.map &&
            Map.getProgramLocationKey(props.selectedMarkerProgram) !==
            Map.getProgramLocationKey(state.selectedMarkerProgram)) {
            Map.setSelectedMarkerIcons(state, props);
            state.selectedMarkerProgram = props.selectedMarkerProgram;
            window.selectedLocationKey = Map.getProgramLocationKey(props.selectedMarkerProgram);

            return state;
        } else if (state.map &&
            Map.getProgramLocationKey(props.highlightedProgram) !==
            Map.getProgramLocationKey(state.highlightedProgram)) {
            Map.setMarkerIcons(state, props);

            state.highlightedProgram = props.highlightedProgram;

            return state;
        } else if (state.map && mapProgramParams &&
            ((JSON.stringify(mapProgramParams) !== JSON.stringify(state.mapProgramParams)) ||
                ((props.showMap !== state.showMap)))) {

            Map.removeExistingMarkers(state.markers);

            const programMarkers = Map.addProgramMarkers(state, props);
            const markers = [
                ...Map.addSearchedLocationMarker(mapProgramParams.searchLocation, state),
                ...programMarkers,
            ];

            if (state.clusterer) {
                state.clusterer.clearMarkers();
            }
            state.clusterer = new window.MarkerClusterer(state.map, programMarkers.map(marker => marker.marker), {imagePath: '/images/m'});

            Map.setMapZoomLevel(mapProgramParams, markers, state);

            markers.forEach(marker => {
                marker.marker.setMap(state.map);
            });

            state.markers = markers;
            state.mapProgramParams = mapProgramParams;
            state.showMap = props.showMap;

            props.onClearMarkerClick();

            return state;
        }

        return null;
    };

    static setMarkerIcons = (state, props) => {
        if (state.highlightedProgram) {
            if (Map.getProgramLocationKey(props.selectedMarkerProgram) === Map.getProgramLocationKey(state.highlightedProgram)) {
                Map.updateMarkerIcon(state.markers, state.highlightedProgram, state.selectedCampusMarkerIcon);
            } else {
                Map.updateMarkerIcon(state.markers, state.highlightedProgram, state.campusMarkerIcon);
            }
        }
        if (props.highlightedProgram) {
            if (Map.getProgramLocationKey(props.selectedMarkerProgram) === Map.getProgramLocationKey(props.highlightedProgram)) {
                Map.updateMarkerIcon(state.markers, props.highlightedProgram, state.selectedHighlightedCampusMarkerIcon);
            }
            else {
                Map.updateMarkerIcon(state.markers, props.highlightedProgram, state.highlightedMarkerIcon);
            }
        }
    };

    static setSelectedMarkerIcons = (state, props) => {
        if (state.selectedMarkerProgram) {
            let program = state.markers.find(p => p.programLocationKey === Map.getProgramLocationKey(state.selectedMarkerProgram));
            if (program) {
                program.marker.setIcon(state.campusMarkerIcon);
            }
        }
        if (props.selectedMarkerProgram) {
            let program = state.markers.find(p => p.programLocationKey === Map.getProgramLocationKey(props.selectedMarkerProgram));
            if (program) {
                if (props.showMap)
                    program.marker.setIcon(state.selectedCampusMarkerIcon);
                else {
                    program.marker.setIcon(state.selectedHighlightedCampusMarkerIcon);
                }
            }
        }
    };

    static updateMarkerIcon = (markers, program, icon) => {
        let marker = markers.find(marker => marker.programLocationKey === Map.getProgramLocationKey(program));
        if (marker !== undefined) {
            marker.marker.setIcon(icon);
        }
    };

    static setMapZoomLevel = (mapProgramParams, markers, state) => {
        if (mapProgramParams.searchLocation && mapProgramParams.programs.length === 0) {
            const locationSearched = {
                lat: mapProgramParams.searchLocation.latitude,
                lng: mapProgramParams.searchLocation.longitude
            };
            state.map.setCenter(locationSearched);
            state.map.setZoom(10);
        } else if (markers.length > 0) {
            let bounds = new window.google.maps.LatLngBounds();
            markers.forEach(programMarker =>
                bounds.extend(programMarker.marker.getPosition())
            );

            state.map.fitBounds(bounds);
        } else {
            state.map.setCenter(state.defaultLocation);
            state.map.setZoom(5);
        }
    };

    static addProgramMarkers = (state, props) => {
        const programMarkers = [];
        const programs = props.mapProgramParams.programs;
        if (programs.length) {

            const locationsWithPrograms = this.getCampusLocationsWithPrograms(programs);
            for (let programLocationKey in locationsWithPrograms) {
                if (locationsWithPrograms[programLocationKey][0].latitude &&
                    locationsWithPrograms[programLocationKey][0].longitude) {

                    const marker = new window.google.maps.Marker({
                        position: {
                            lat: locationsWithPrograms[programLocationKey][0].latitude,
                            lng: locationsWithPrograms[programLocationKey][0].longitude
                        },
                        icon: state.campusMarkerIcon,
                        label: {
                            color: 'white',
                            fontFamily: 'Nunito',
                            fontWeight: '600',
                            text: `${locationsWithPrograms[programLocationKey].length}`,
                        },
                        zIndex: 200,
                    });

                    const locationInformation = new window.google.maps.InfoWindow({
                        content: ReactDOMServer.renderToString(<MarkerLocationInformation
                            programs={locationsWithPrograms[programLocationKey]}
                            searchLocation={props.mapProgramParams.searchLocation}
                        />)
                    });
                    const highlightedMarkerIcon = state.highlightedMarkerIcon;
                    const selectedHighlightedCampusMarkerIcon = state.selectedHighlightedCampusMarkerIcon;
                    const campusMarkerIcon = state.campusMarkerIcon;
                    const selectedCampusMarkerIcon = state.selectedCampusMarkerIcon;

                    marker.addListener('mouseover', () => {
                        if (window.selectedLocationKey === programLocationKey) {
                            marker.setIcon(selectedHighlightedCampusMarkerIcon);
                        } else {
                            marker.setIcon(highlightedMarkerIcon);
                        }
                        locationInformation.open(state.map, marker);

                    });
                    marker.addListener('mouseout', () => {
                        if (window.selectedLocationKey === programLocationKey) {
                            marker.setIcon(selectedCampusMarkerIcon);
                        } else {
                            marker.setIcon(campusMarkerIcon);
                        }
                        locationInformation.close();
                    });

                    marker.addListener('click', () => props.onMarkerClick(locationsWithPrograms[programLocationKey]));

                    programMarkers.push({
                        programLocationKey: programLocationKey,
                        marker: marker
                    });
                }
            }
        }

        return programMarkers;
    };

    static getCampusLocationsWithPrograms = (programs) => programs.reduce(
        (accumulator, program) => {
            if (program.latitude && program.longitude) {
                const programLocationKey = Map.getProgramLocationKey(program);

                if (accumulator[programLocationKey])
                    accumulator[programLocationKey] = [...accumulator[programLocationKey], program];
                else
                    accumulator[programLocationKey] = [program];
            }
            return accumulator;
        }, {});

    static getProgramLocationKey = (program) => {
        if (program)
            return `${program.latitude}-${program.longitude}`;
    };

    static addSearchedLocationMarker = (searchLocation, state) => {
        let markers = [];
        if (searchLocation) {
            markers.push({
                marker:
                    new window.google.maps.Marker({
                        position: {
                            lat: searchLocation.latitude,
                            lng: searchLocation.longitude,
                        },
                        icon: state.searchLocationMarkerIcon,
                        zIndex: 100
                    })
            });
        }

        return markers;
    };

    static removeExistingMarkers = (markers) => {
        if (markers && markers.length > 0) {
            markers.forEach(marker => {
                marker.marker.setMap(null);
            });
        }
    };

    static getInitialState = () => ({
        map: new window.google.maps.Map(
            document.getElementById('map'),
            {
                maxZoom: 18,
                zoom: 5,
                gestureHandling: "greedy",
                mapTypeControl: false,
                center: {lat: 32.397, lng: -97.644},
                styles: [
                    {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ]
            }
        ),
        defaultLocation: new window.google.maps.LatLng({lat: 32.397, lng: -97.644}),
        campusMarkerIcon: {
            url: CampusMarker,
            scaledSize: new window.google.maps.Size(45, 45),
            labelOrigin: new window.google.maps.Point(23, 20)
        },
        highlightedMarkerIcon: {
            url: CampusHoverMarker,
            scaledSize: new window.google.maps.Size(45, 45),
            labelOrigin: new window.google.maps.Point(23, 20)
        },
        searchLocationMarkerIcon: {
            url: SearchLocationMarker,
            scaledSize: new window.google.maps.Size(40, 40)
        },
        selectedCampusMarkerIcon: {
            url: CampusMarker,
            scaledSize: new window.google.maps.Size(60, 60),
            labelOrigin: new window.google.maps.Point(31, 26)
        },
        selectedHighlightedCampusMarkerIcon: {
            url: CampusHoverMarker,
            scaledSize: new window.google.maps.Size(60, 60),
            labelOrigin: new window.google.maps.Point(31, 26)
        },
    });

    initMap = () => {
        this.setState(Map.getInitialState());
        window.MarkerClusterer = require("@google/markerclusterer");
        window.googleMaps = true;

    };

    componentDidMount = () => {
        if (window.googleMaps) {
            this.setState(Map.getInitialState());
        } else {
            window.initMap = this.initMap;
            const googleScript = document.createElement('script');
            googleScript.type = 'text/javascript';
            googleScript.async = true;
            googleScript.defer = true;
            googleScript.id = 'google-maps';
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${Environment.googleMapsApi()}&callback=initMap`;
            document.body.appendChild(googleScript);
        }
    };

    render = () => <div id="map" className="map-container"></div>;
}
