import Environment from "util/Environment";
import Fab from "@material-ui/core/Fab";
import React from "react";
import Rectangle from "assets/images/rectangle.svg";
import HomeImage from "assets/images/home.svg";
import LocationDropdown from "components/locationDropdown/LocationDropdown";
import Toast from "components/toast/Toast";
import LocationIcon from '@material-ui/icons/LocationOn';
import {OnlineCheckbox} from "components/onlineCheckbox/OnlineCheckbox";
import OnlineLocationPill from "components/onlineLocationPill/OnlineLocationPill";
import 'components/home/css/HomeFilters.scss';
import {DynamicSearch} from "components/dynamicSearch/DynamicSearch";
import axios from "axios";
import * as PropTypes from "prop-types";

class HomeFilters extends React.Component {
    state = {
        selectedLocation: undefined,
        lat: undefined,
        lng: undefined,
        openSnackBar: false,
        isOnline: false,
        term: ''
    };

    getUserLocation = () => {
        const locationDetectionTimeoutInMs = 5000;
        const maximumTimeToStoreLocationInMs = 30000;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.handleGetPositionSuccess,
            this.props.onHandleGetLocationError, {
                timeout: locationDetectionTimeoutInMs,
                maximumAge: maximumTimeToStoreLocationInMs
            });
        }
    }

    handleGetPositionSuccess = (position) => {
        axios.get(`${Environment.apiUrl()}/locations/lookup`, {
            params: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            },
            headers: {
                'Session-Id': sessionStorage.getItem('connectSessionId'),
                'Page-Url': window.location.href
            }
        }).then(response => {
            this.setLocation({
                formattedAddress: response.data,
                geometry: {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }
            });
        }, error => {
            this.setLocation({
                formattedAddress: "Current Location",
                geometry: {
                    location: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }
            });
        });
    }

    setLocation = (geoLocation) => {
        this.setState({
            selectedLocation: geoLocation.formattedAddress,
            lat: geoLocation.geometry.location.lat,
            lng: geoLocation.geometry.location.lng
        })
    };

    clearLocation = () => {
        this.setState({
            selectedLocation: '',
            lat: undefined,
            lng: undefined,
        })
    };

    handleSnackBarOpen = () => {
        this.setState({
            openSnackBar: true
        })
    };

    handleSnackBarClose = () => {
        this.setState({
            openSnackBar: false
        })
    };

    setSearchTerm = (term) => this.setState({term: term});

    clearSearchTerm = () => this.setState({term: ''});

    toggleIsOnline = () => {
        this.setState({
            isOnline: !this.state.isOnline
        })
    };

    redirectToExplorePage = () => {
        this.props.history.push({
            pathname: '/explore',
            search: `onlineOnly=${this.state.isOnline}` +
                (!!this.state.selectedLocation && !this.state.isOnline ? `&location=${this.state.selectedLocation}` : '') +
                (!!this.state.lat && !this.state.isOnline ? `&lat=${this.state.lat}` : '') +
                (!!this.state.lng && !this.state.isOnline ? `&lng=${this.state.lng}` : '') +
                (!!this.state.term ? `&term=${this.state.term}` : '')
        });
    };

    render = () => {

        return (<div className='home-filters'>
            <div className='home-filters__container'>
                <img className='home__rectangle' src={Rectangle} alt=''/>
                <h1 className='home-filters__title'>
                    Build Your Path to a Meaningful Career
                </h1>
                <p className='home-filters__subtitle'>
                    It takes a lot to get to where you want to be.<br/>
                    Start here: explore training options, contact programs you’re interested in, and apply for funding
                    all in one place.
                </p>
                <div className='home-filters__filter'>
                    <DynamicSearch
                        setSearchTerm={this.setSearchTerm}
                        clearSearchTerm={this.clearSearchTerm}
                        term={this.state.term}
                    />
                </div>
                <div className='home-filters__filter'>
                    {this.state.isOnline ? <OnlineLocationPill/> :
                        <LocationDropdown
                            onSelection={this.setLocation}
                            onClearSearch={this.clearLocation}
                            onHandleGetLocationError={this.handleSnackBarOpen}
                            selectedLocation={this.state.selectedLocation}
                            firstVisit={this.props.firstVisit}
                        />
                    }
                </div>
                <div className='home-filters__filter'>
                    <OnlineCheckbox handleToggle={this.toggleIsOnline}
                                    isOnline={this.state.isOnline}/>
                </div>
                <Fab className='home-filters__explore-programs-button'
                     onClick={this.redirectToExplorePage}
                >
                    Explore Programs
                </Fab>
            </div>
            <img src={HomeImage} className='home-filters__image' alt=''/>
            <Toast
                icon={LocationIcon}
                open={this.state.openSnackBar}
                onClose={this.handleSnackBarClose}
                timeout={5000}
                message={'We are unable to retrieve your location. Please check your settings.'}
            />
        </div>);
    };
}

HomeFilters.propTypes = {
    firstVisit: PropTypes.bool,
    onHandleGetLocationError: PropTypes.any,
    history: PropTypes.any
}

export default HomeFilters;