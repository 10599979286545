import React from "react";
import Rectangle from 'assets/images/rectangle.svg';
import ClosestOptions from 'assets/images/closest-options.svg';
import Wallet from 'assets/images/wallet.svg';
import Schedule from 'assets/images/schedule.svg';
import ScrollAnimation from "react-animate-on-scroll";
import 'components/home/css/HomeTraining.scss'


export const HomeTraining = () =>
    <>
        <div className='home-training'>
            <img className='home__rectangle' src={Rectangle} alt=''/>
            <h2 className={'home-training__title'}>Find training that fits your life</h2>
            <div className='home-training__items'>
                <ScrollAnimation animateIn="slideInUp" duration={.5} animateOnce={true}>
                    <div className='home-training__item'>
                        <img src={ClosestOptions} className='home-training__item-image' alt=''/>
                        <div className='home-training__item-description'>See your closest options on a map and find one
                            that fits your commute
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" duration={1} animateOnce={true}>
                    <div className='home-training__item'>
                        <img src={Wallet} className='home-training__item-image' alt=''/>
                        <div className='home-training__item-description'>Compare costs of programs and find one that
                            fits your budget
                        </div>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInUp" duration={1.5} animateOnce={true}>
                    <div className='home-training__item'>
                        <img src={Schedule} className='home-training__item-image' alt=''/>
                        <div className='home-training__item-description'>Look at start dates and durations and find one
                            that fits your timeline
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
        <div className='home-training-mobile'>
            <div className='home-training-mobile__container'>
                <img className='home__rectangle' src={Rectangle} alt=''/>
                <h1 className={'home-training__title'}>Find training that fits your life</h1>
                <div className='home-training__items'>
                    <div className='home-training__item'>
                        <img src={ClosestOptions} className='home-training__item-image' alt=''/>
                        <div className='home-training__item-description'>See your closest options on a map and find one
                            that fits your commute
                        </div>
                    </div>
                    <div className='home-training__item'>
                        <img src={Wallet} className='home-training__item-image' alt=''/>
                        <div className='home-training__item-description'>Compare costs of programs and find one that
                            fits your budget
                        </div>
                    </div>
                    <div className='home-training__item'>
                        <img src={Schedule} className='home-training__item-image' alt=''/>
                        <div className='home-training__item-description'>Look at start dates and durations and find one
                            that fits your timeline
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;