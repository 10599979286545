import React from 'react';
import 'components/howTo/css/HowTo.scss';
import Icon from "@material-ui/core/Icon";
import 'components/onlineLocationPill/css/OnlineLocationPill.scss';

const OnlineLocationPill = () =>
    <div className="online-location-pill__container">
        <div className="online-location-pill">
            <Icon className="online-location-pill__icon--desktop">location_on</Icon>
            <Icon className="online-location-pill__icon--mobile" fontSize={'small'}>location_on</Icon>
            <span
                className="online-location-pill__label">Online
        </span>
        </div>
    </div>;

export default OnlineLocationPill;