import React from 'react';
import 'components/footer/css/Footer.scss';
import logo from 'assets/images/meritize-logo.svg';

const Footer = () => {
    return (
        <div className="footer">
            <div className='meritize-logo'>
                <a className='meritize-logo-link' href='https://www.meritize.com/' rel="noopener noreferrer" target='_blank'><img className="meritize-logo" alt="Meritize - Find Training Programs"
                    src={logo}/></a>
            </div>
            <div className='footer__info'>
                <div className='footer__contact-us-section'>
                    <div className='footer__contact-us'><a className='footer__contact-us-link' href='https://www.meritize.com/about-meritize/contact/' rel="noopener noreferrer" target='_blank'>Contact Us</a></div>

                    <div className='footer__address'>
                        5805 Main Street<br/>
                        Suite 220<br/>
                        Frisco, TX 75034<br/>
                        833 - 637 - 4848
                    </div>
                </div>
                <div className="footer__hours-section">
                    <div className="footer__hours">Hours</div>
                    <div className="footer__hours-text">
                        Mon-Fri: 8am-7pm CST
                    </div>
                </div>
                <div className="footer__legal-section">
                    <div className="footer__legal">Legal</div>
                    <a className="footer__legal-terms" rel="noopener noreferrer" target="_blank" href="https://www.meritize.com/terms-of-use/">Terms of Use</a>
                    <a className="footer__legal-privacy" rel="noopener noreferrer" target="_blank" href="https://www.meritize.com/privacy-policy/">Privacy
                        Policy</a>
                    <a className="footer__legal-accessibility"
                       rel="noopener noreferrer" target="_blank" href="https://www.meritize.com/accessibility/"
                    >Accessibility</a>
                    <a className="footer__legal-license" rel="noopener noreferrer" target="_blank" href="https://www.meritize.com/licenses/">Licenses</a>
                </div>

                <div className='footer__more-section'>
                    <div className='footer__more'>More</div>
                    <a className="footer__more-about" rel="noopener noreferrer" target="_blank" href="https://www.meritize.com/about/">About Meritize</a>
                    <a className="footer__more-faqs" rel="noopener noreferrer" target="_blank" href="https://www.meritize.com/students/faq/">Frequently Asked Questions for Students</a>
                </div>
            </div>

            <div className={'footer__legal-verbiage-section '}>
                <p>
                  Meritize Lending, LLC, NMLS ID 1661035 (NMLS Consumer Access <a href="https://www.nmlsconsumeraccess.org/" rel="noopener noreferrer" target="_blank">https://www.nmlsconsumeraccess.org/</a>)
                </p>
                <p>
                  Meritize Financial, Inc. NMLS ID 1986399 (NMLS Consumer Access <a href="https://www.nmlsconsumeraccess.org/" rel="noopener noreferrer" target="_blank">https://www.nmlsconsumeraccess.org/</a>)
                </p>
                <p>
                    Terms and Conditions apply. Meritize branded loan products are educational loans issued by Meritize Lending, LLC**. Meritize reserves the right to modify or discontinue products and benefits at any time without notice. To qualify, a borrower must be a U.S. citizen, permanent resident or hold an Employment Authorization Document and meet Meritize's underwriting requirements. If approved for a loan the actual loan amount, term, payment, and APR amount of loan that a customer qualifies for may vary based on credit determination, state law and other factors. Meritize does not warrant or guarantee any claims made herein, including, but not limited to, gaining admission to a program, concerning the quality or financial strength of any educational institution, securing funding or obtaining employment. Our final loan offer may differ as a result of the school chosen and the associated cost of tuition. Not all schools are eligible for funding. Meritize does not offer educational loans in the following states: ME, VT, PR. All rights reserved.
                </p>
                <p>*Program information for schools that are not active partners is subject to change and may be out of date. For the most up to date information on programs offered, tuition, and other relevant information, please visit the school’s website.</p>
                <p>**For residents of SC, loan is made by Meritize Financial, Inc.</p>
            </div>
        </div>
    );
};

export default Footer;