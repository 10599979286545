import { createTheme } from '@material-ui/core/styles';
import variables from 'index.scss'


const defaultColor = "#fff";
const theme = createTheme({
    palette: {
        primary: {
            main: variables.primary || defaultColor,
            contrastText: variables.white || defaultColor,
        },
        secondary: {
            main: variables.secondary || defaultColor,
            contrastText: variables.white || defaultColor,
        },
        error: {
            main: variables.error || defaultColor,
        }
    },

});

export default theme;
