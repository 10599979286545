import Environment from "util/Environment";
import React, {Component} from 'react';
import 'App.scss';

import {Router} from 'react-router-dom';
import Header from "components/header/Header";
import {ThemeProvider} from '@material-ui/styles';
import theme from "theme/muiTheme";
import Toast from "components/toast/Toast";
import InfoIcon from '@material-ui/icons/Info';
import {appHistory} from "AppHistory";
import {AppRouter} from "AppRouter";

class App extends Component {
    state = {
        announcementToast: undefined,
    };

    constructor(props) {
        super(props);
        if (sessionStorage.getItem('connectSessionId') == null) {
            const uuidv4 = require('uuid/v4');
            sessionStorage.setItem('connectSessionId', `${uuidv4()}-${Date.now()}`);
        }
    }

    handleToastClose = () => {
        this.setState({
            announcementToast: undefined
        });

        sessionStorage.setItem('announcementDismissed', 'true');
    };

    componentDidMount = () => {
        if (Environment.announcementMessage()
            && sessionStorage.getItem('announcementDismissed') === null) {

            this.setState({
                announcementToast: Environment.announcementMessage()
            })
        }
    };

    render = () => (
        <ThemeProvider theme={theme}>
            <Toast
                icon={InfoIcon}
                timeout={undefined}
                message={this.state.announcementToast || ''}
                open={!!this.state.announcementToast}
                onClose={this.handleToastClose}
            />
            <Header/>
            <div className='master-container'>
                <Router history={appHistory}>
                    <AppRouter history={appHistory}/>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
