import Fab from "@material-ui/core/Fab";
import React from "react";
import Rectangle from "assets/images/rectangle.svg";
import Funding from "assets/images/funding.svg";
import ScrollAnimation from "react-animate-on-scroll";
import 'components/home/css/HomeFunding.scss';

export const HomeFunding = () =>
    <>
        <div className='home-funding'>
            <img className='home__rectangle' src={Rectangle} alt=''/>
            <h2 className='home-funding__title'>Pay for your training</h2>
            <div className='home-funding__container'>
                <ScrollAnimation animateIn="slideInLeft" duration={1} animateOnce={true}>
                    <div className='home-funding__description-container'>
                        <div className='home-funding__description'>We believe you are more than your credit score.
                            Use your academic history or military service to potentially
                            qualify for a merit based loan to pay for your training.
                        </div>
                        <div className='home-funding__description'>Don’t worry. Checking your loan options will not
                            impact
                            your credit score.
                        </div>
                        <a href='https://apply.meritize.com'
                           target="_blank"
                           rel="noopener noreferrer">
                            <Fab className='home-funding__rate-button'>
                                Get Your Rate
                            </Fab>
                        </a>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="slideInRight" duration={1} animateOnce={true}>
                    <div className='home-funding__image'><img src={Funding} alt=''/></div>
                </ScrollAnimation>
            </div>
        </div>
        <div className='home-funding-mobile'>
            <div className='home-funding-mobile__container'>
                <img className='home__rectangle' src={Rectangle} alt=''/>
                <h1 className='home-funding__title'>Pay for your training</h1>
                <div className='home-funding__container'>
                    <img className='home-funding__image' src={Funding} alt=''/>
                    <div className='home-funding__description-container'>
                        <div className='home-funding__description'>We believe you are more than your credit score.
                            Use your academic history or military service to potentially
                            qualify for a merit based loan to pay for your training.
                        </div>
                        <div className='home-funding__description'>Don’t worry. Checking your loan options will not
                            impact
                            your credit score.
                        </div>
                        <a href='https://apply.meritize.com'
                           target="_blank"
                           rel="noopener noreferrer"
                           className='home-funding__rate-button-link'>
                            <Fab className='home-funding__rate-button'>
                                Get Your Rate
                            </Fab>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>;
