import React from 'react';
import {Link} from 'react-router-dom';
import './css/ContactProgramBreadcrumb.scss';
import PropTypes from "prop-types";

const ContactProgramBreadcrumb = (props) => {

    const handleSearchResultsClick = () => {
        setTimeout(() => props.history.push(props.previousUrl), 100);
    }

    return (
        <div className="breadcrumbs__container">
            <div className="contact-program__breadcrumbs-inner-container">
            <span onClick={handleSearchResultsClick} className="breadcrumbs__links_search">
                Search Results
            </span>

            <i className="material-icons breadcrumbs__material_icon">chevron_right</i>
            <Link to={{pathname: `/program/${props.programId}`, previousUrl: props.previousUrl}} className="breadcrumbs__links_details">
                Program Details
            </Link>

            <i className="material-icons breadcrumbs__material_icon1">chevron_right</i>
            <span className="breadcrumbs__links_contact">
                Contact Program
            </span>
            </div>
        </div>
    );
}

export default ContactProgramBreadcrumb;

ContactProgramBreadcrumb.propTypes = {
    history: PropTypes.any,
    programId: PropTypes.number,
    previousUrl: PropTypes.string,
};