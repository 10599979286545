import Environment from "util/Environment";
import React, {Component} from 'react';
import axios from "axios";
import "pages/programDetailsPage/css/ProgramDetailsPage.scss"
import Icon from "@material-ui/core/Icon";
import HowTo from "components/howTo/HowTo";
import ProgramDetails from "components/programDetails/ProgramDetails";
import MobileProgramFooter from "components/mobileProgramFooter/MobileProgramFooter";
import {Helmet} from "react-helmet";
import Footer from "components/footer/Footer";
import ProgramDetailsBreadcrumb from "components/programDetailsBreadcrumb/ProgramDetailsBreadcrumb";
import PropTypes from "prop-types";
import ContactProgramButton from "../../components/contactProgramButton/ContactProgramButton";
import {generateApplyUrl} from "../../non-ui-components/urlGeneration/urlGeneration";
import CTAButton from "../../components/ctaButton/CTAButton";
import SecureFundingImage from 'assets/images/secure-funding.svg';


class ProgramDetailsPage extends Component {

    state = {
        program: {},
        schoolLink: undefined,
    };

    componentDidMount() {
        const params = this.props.match.params;
        const apiBaseApi = Environment.apiUrl();
        axios.get(`${apiBaseApi}/programs/${params.id}`,
            {
                headers: {
                    'Session-Id': sessionStorage.getItem('connectSessionId'),
                    'Page-Url': window.location.href
                }
            }).then(response => {
            if (response.data.id !== parseInt(params.id)) {
                this.props.history.push({pathname: '/'});
            }
            this.setState({program: response.data});
            return response.data;
        }).then(
            data => axios.get(`${apiBaseApi}/schools/website/?schoolId=${data.schoolId}`)
        ).then(response => {
          console.log(response);
            this.setState({
                schoolLink: response.data
            });
        }).catch(error => {
          console.log(error);
          this.props.history.push({pathname: '/404'});
        });
        window.scrollTo(0, 0)
    }

    getPageDescription() {
        if (this.state.program.programDescription == null) {
            return `${this.state.program.programName} at ${this.state.program.schoolName} in ${this.state.program.campusAddress}`;
        }
        return this.state.program.programDescription;
    }

    render() {
        let program = this.state.program;
        let schoolLink = this.state.schoolLink;
        let previousUrl = this.props.location.previousUrl || '/explore';
        let showApplyButton = this.state.program.isLendable;

        return (
            <React.Fragment>
                <div className='program-content'>

                    {!program.programName &&
                    <div className='program-details-page__empty'></div>
                    }
                    {program.programName &&
                    <div className='program-details-page'>
                        <ProgramDetailsBreadcrumb
                            history={this.props.history}
                            previousUrl={previousUrl}
                        />
                        <Helmet>
                            <title>{program.programName} - {program.schoolName} - {program.campusAddress}</title>
                            <meta name="description" content={this.getPageDescription()}/>
                            <link rel="canonical" href={`https://www.meritizeconnect.com/program/${program.id}`}/>
                        </Helmet>
                        <div className='program-details-page__program-details'>
                            <ProgramDetails program={program} schoolLink={schoolLink} history={this.props.history}
                                            previousUrl={previousUrl} showApplyButton={showApplyButton}/>
                        </div>

                        <div className="program-details-page__section-container">
                            <div className='program-details-page__description-section'>

                                <div className='program-details-page__time-info'>
                                    <div className="program-details-page__start-box">
                                        <div className='program-details-page__start-icon'><Icon
                                            className='program-details-page_time-icon'>date_range</Icon></div>
                                        <div className="program-details-page__start">{program.startDate}</div>
                                    </div>

                                    <div className='program-details-page__schedule-box'>
                                        <div className='program-details-page__schedule-icon'><Icon
                                            className='program-details-page_time-icon'>access_time</Icon></div>
                                        <div className='program-details-page__schedule'>
                                            {program.isFullTime ? 'Full-Time' : 'Part-Time'}
                                        </div>
                                    </div>

                                    <div className='program-details-page__duration-box'>
                                        <div className='program-details-page__duration-icon'><Icon
                                            className='program-details-page_time-icon'>hourglass_empty</Icon></div>
                                        <div className='program-details-page__duration'>
                                            {program.duration === '' ? 'Contact Program for Duration' : program.duration}
                                        </div>
                                    </div>
                                </div>

                                <div className='program-details-page__description-box'>
                                    <h2 className="program-description-label">
                                        Program Description
                                    </h2>

                                    <div className='program-details-page__description'>
                                        {program.programDescription ? program.programDescription :
                                            'Contact Program for Description'}
                                    </div>
                                </div>

                                <div className="program-details-page__contact-program-container">
                                    <ContactProgramButton programId={program.id} promoCode={program.promoCode} previousUrl={previousUrl} />
                                </div>

                                <img src={SecureFundingImage} className='secure-funding__image' alt=''/>

                                <div className='program-details-page__secure-rate'>
                                    <h2 className="program-details-page__secure-rate-label">
                                        Secure Funding
                                    </h2>

                                    {program.isLendable ?
                                        program.isPreferred ?
                                            <div className='program-details-page__secure-rate-description'
                                                 id='program-details-page__secure-rate-description'>
                                                Get pre-approved for a merit loan based on more than just your credit
                                                score. We believe your past achievements
                                                are an important indicator of your future potential. We consider
                                                academic history or military service
                                                in addition to your credit.<br/><br/>

                                                <i>Full tuition funding options are available for this program! Checking
                                                    your loan options will not impact your credit score.</i>
                                            </div>
                                            :
                                            <div
                                                className='program-details-page__secure-rate-description'
                                                id='program-details-page__secure-rate-description'>
                                                Get pre-approved for a merit loan based on more than just your
                                                credit score. We believe your past achievements are an important
                                                indicator of your future potential. We consider academic
                                                history or military service in addition to
                                                your credit. <br/><br/>

                                                <i>Partial tuition funding options are available
                                                    for this program. Checking your loan options will not impact
                                                    your credit score.</i>
                                            </div>
                                        :
                                        <div
                                            className='program-details-page__secure-rate-description'
                                            id='program-details-page__secure-rate-description'>
                                            Funding for this program is not available through Meritize
                                            today.&nbsp;
                                            <a className='program-details-page__secure-rate-contact'
                                               rel="noopener noreferrer"
                                               target="_blank"
                                               href="https://www.meritize.com/about-meritize/contact/">Click
                                                here to
                                                express your interest in a loan.</a>
                                        </div>
                                    }

                                    {showApplyButton &&
                                        <CTAButton url={generateApplyUrl(program)} text="Check Funding Options" style="primary-light" analyticsClass={'analytics-button-check-funding-options'}/>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='program-details-page__how-to'>
                            <HowTo
                                step={1}
                                history={this.props.history}
                                fundingLink={generateApplyUrl(program)}
                                contactLink={this.generateContactLink(program)}
                            />
                        </div>

                        <div className='program-details-page__mobile-footer'>
                            <MobileProgramFooter program={program} showApplyButton={showApplyButton} previousUrl={previousUrl} />
                        </div>

                    </div>
                    }
                </div>
                <Footer/>
                <div className='program-details-page__bottom-margin'/>
            </React.Fragment>
        )
    }

    generateContactLink(program) {
        return program.promoCode ?
            `/contact/${program.id}/?promo=${encodeURI(program.promoCode)}` :
            `/contact/${program.id}/`;
    }
}

export default ProgramDetailsPage;

ProgramDetailsPage.propTypes = {
    location: PropTypes.any,
    history: PropTypes.any
};